import { CLOSE_INFORMATION_MODAL } from "@App/constants/appConstants";
import { customEventSubscribe, customEventUnsubscribe } from "@Utils/utils";
import { useEffect, useState } from "react";

const TextInputLogic = () => {
  const [isTextVisible, setIsTextVisible] = useState(false);
  const [showinformationModal, setShowinformationModal] = useState(false);

  useEffect(() => {
    customEventSubscribe(CLOSE_INFORMATION_MODAL, () =>
      setShowinformationModal(false)
    );
    return () => {
      customEventUnsubscribe(CLOSE_INFORMATION_MODAL, () =>
        setShowinformationModal(false)
      );
    };
  }, []);
  return {
    isTextVisible,
    showinformationModal,
    setIsTextVisible,
    setShowinformationModal,
  };
};

export default TextInputLogic;
