import UserSession from "@App/auth/userSession";
import { config } from "@App/config/config";
import axios from "axios";

type GetEventsProps = {
  page?: number;
  pageSize?: number;
  startDate: string;
  endDate?: string;
};

export type UpdateEventUserPreferencesProps = {
  eventId: string;
  userId: string;
  preference: any;
};

// TODO: Set Headers (Auth and TenantId?) in one place (Base service): axios.defaults.headers.common['Authorization'] = `Bearer ...`;

export const getEvents = async (data: GetEventsProps) => {
  const { page, pageSize, startDate, endDate } = data;
  let params: GetEventsProps = { page, pageSize, startDate };
  if (endDate) {
    params["endDate"] = endDate;
  }

  const response = await axios.get(`${config.backendDomain}/event/Search`, {
    headers: {
      ...(UserSession.accessToken && {
        Authorization: `Bearer ${UserSession.accessToken}`,
      }),
      "cbp-TenantId": config.cbpTenantId,
    },
    params,
  });

  return response;
};

export const getEventDetails = async (eventId: string) => {
  const response = await axios.get(`${config.backendDomain}/event/${eventId}`, {
    headers: {
      ...(UserSession.accessToken && {
        Authorization: `Bearer ${UserSession.accessToken}`,
      }),
      "cbp-TenantId": config.cbpTenantId,
    },
  });

  return response;
};

export const getEventCategories = async () => {
  const response = await axios.get(`${config.backendDomain}/eventcategories`, {
    headers: {
      "cbp-TenantId": config.cbpTenantId,
    },
  });

  return response;
};

export const updateEventPreference = async (
  props: UpdateEventUserPreferencesProps
) => {
  const response = await axios.put(
    `${config.backendDomain}/event/${props.eventId}/user/${props.userId}`,
    props.preference,
    {
      headers: {
        Authorization: `Bearer ${UserSession.accessToken}`,
        "cbp-TenantId": config.cbpTenantId,
      },
    }
  );

  return response;
};
