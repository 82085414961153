type SvgsProps = {
  color?: string;
};

const Calendar = ({ color = "#858383" }: SvgsProps) => {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.90912 10.6819H6.90912V12.6819H4.90912V10.6819ZM18.9091 4.68188V18.6819C18.9091 19.7919 18.0191 20.6819 16.9091 20.6819H2.90912C1.79912 20.6819 0.909119 19.7819 0.909119 18.6819V4.68188C0.909119 3.58188 1.80912 2.68188 2.90912 2.68188H3.90912V0.681885H5.90912V2.68188H13.9091V0.681885H15.9091V2.68188H16.9091C18.0191 2.68188 18.9091 3.58188 18.9091 4.68188ZM2.90912 6.68188H16.9091V4.68188H2.90912V6.68188ZM16.9091 18.6819V8.68188H2.90912V18.6819H16.9091ZM12.9091 12.6819V10.6819H14.9091V12.6819H12.9091ZM8.90912 12.6819V10.6819H10.9091V12.6819H8.90912ZM4.90912 14.6819H6.90912V16.6819H4.90912V14.6819ZM12.9091 16.6819V14.6819H14.9091V16.6819H12.9091ZM8.90912 16.6819V14.6819H10.9091V16.6819H8.90912Z"
        fill={color}
      />
      <path
        d="M6.90912 10.6819H4.90912V12.6819H6.90912V10.6819Z"
        fill={color}
      />
      <path
        d="M12.9091 10.6819V12.6819H14.9091V10.6819H12.9091Z"
        fill={color}
      />
      <path
        d="M8.90912 10.6819V12.6819H10.9091V10.6819H8.90912Z"
        fill={color}
      />
      <path
        d="M6.90912 14.6819H4.90912V16.6819H6.90912V14.6819Z"
        fill={color}
      />
      <path
        d="M12.9091 14.6819V16.6819H14.9091V14.6819H12.9091Z"
        fill={color}
      />
      <path
        d="M8.90912 14.6819V16.6819H10.9091V14.6819H8.90912Z"
        fill={color}
      />
    </svg>
  );
};

export default Calendar;
