import { Navbar } from "@Layouts/index";
import "./Calendar.scss";
import {
  CalendarSelect,
  Tag,
  EventsList,
  SearchInput,
} from "@Components/index";
import CalendarLogic from "./CalendarLogic";
import { Chevron, Heart } from "@Components/icons";
import React from "react";

const Calendar = () => {
  const {
    categoryFilters,
    isProcessing,
    isUserLogged,
    events,
    upcomingEvents,
    selectedDate,
    selectedCategory,
    showCategoryLeftScrollButton,
    setSelectedDate,
    handleMonthChange,
    setSelectedCategory,
    setShowCategoryLeftScrollButton,
    showCategoryRightScrollButton,
    searchText,
    setSearchText,
    handleScroll,
  } = CalendarLogic();
  return (
    <Navbar>
      <div className={"calendar-container"}>
        <div className={"calendar-wrapper"}>
          <section>
            <div className={"calendar-top-content"}>
              {categoryFilters && (
                <div className={"calendar-category-filters"}>
                  <div className={"calendar-tags-all"}>
                    <Tag
                      key={`event_tag${-1}`}
                      text={"All"}
                      itemId={"0"}
                      multiple
                      onClick={(selectedTag) => {
                        setSelectedCategory(selectedTag);
                      }}
                      isSelected={selectedCategory === "0"}
                    />
                  </div>

                  {showCategoryLeftScrollButton && (
                    <div>
                      <button
                        className={"calendar-tags-scroll-button"}
                        onClick={() => {
                          handleScroll(true);
                        }}
                      >
                        <Chevron isLeft size={15} color={"#323232"} />
                      </button>
                    </div>
                  )}

                  <div
                    id={"calendar-category-filters"}
                    className={"calendar-tags"}
                    onWheel={(e) => {
                      e.preventDefault();
                      const el = document.getElementById(
                        "calendar-category-filters"
                      );

                      if (el) {
                        setShowCategoryLeftScrollButton(el.scrollLeft > 0);
                      }
                      return true;
                    }}
                  >
                    {categoryFilters?.map((categoryFilter, index) => {
                      return (
                        <Tag
                          key={`calendar_filter_tag${index}`}
                          text={(categoryFilter as any).name}
                          itemId={(categoryFilter as any).id}
                          multiple
                          onClick={(selectedTag) => {
                            setSelectedCategory(selectedTag);
                          }}
                          isSelected={categoryFilter.id === selectedCategory}
                        />
                      );
                    })}
                  </div>

                  {events && showCategoryRightScrollButton && (
                    <button
                      className={"calendar-tags-scroll-button"}
                      onClick={() => {
                        handleScroll();
                      }}
                    >
                      <Chevron size={15} color={"#323232"} />
                    </button>
                  )}
                </div>
              )}

              <div className={"calendar-search"}>
                <SearchInput onSearch={setSearchText} />
              </div>
            </div>
          </section>

          <section>
            <div className={"calendar-select-wrapper"}>
              <div className={"calendar-select"}>
                <CalendarSelect
                  selected={selectedDate}
                  events={
                    selectedCategory === "0"
                      ? events
                      : events?.filter((event) => {
                          if (
                            event.categoryIds &&
                            event.categoryIds.indexOf(selectedCategory) > -1
                          ) {
                            return event;
                          } else {
                            return false;
                          }
                        })
                  }
                  onMonthChange={(selectedMonthDate) => {
                    setSelectedDate(null);
                    handleMonthChange(selectedMonthDate);
                  }}
                  onChange={(selectedDate) => {
                    setSelectedDate(selectedDate);
                    // Update the month as well in case the user selects a day of the next/prev month.
                    handleMonthChange(selectedDate);
                  }}
                />
              </div>
              <div className="calendar-events-wrapper">
                <EventsList
                  items={events}
                  dateFilter={selectedDate}
                  selectedTag={
                    selectedCategory === "0" ? undefined : selectedCategory
                  }
                  searchText={searchText}
                  notLoggedUser={!isUserLogged}
                  isProcessing={isProcessing}
                  {...((selectedDate as any) && {
                    onShowAllClick: () => {
                      setSelectedDate(null);
                    },
                  })}
                ></EventsList>
              </div>
            </div>
          </section>

          {isUserLogged && upcomingEvents && (
            <React.Fragment>
              <section className={"calendar-events-row"}>
                <EventsList
                  title={"Attending events"}
                  items={upcomingEvents.filter((e) => e.user?.isRSVPd)}
                  filter={"rsvp"}
                  isCard={true}
                  verticalScroll={true}
                  isProcessing={isProcessing}
                  hideLoadingStatus
                ></EventsList>
              </section>

              <section className={"calendar-events-row"}>
                <EventsList
                  items={upcomingEvents.filter((e) => e.user?.isFavorite)}
                  filter={"favorites"}
                  title={"Favorites"}
                  titleIcon={<Heart isActive size={30} />}
                  isCard={true}
                  verticalScroll={true}
                  isProcessing={isProcessing}
                  hideLoadingStatus
                ></EventsList>
              </section>
            </React.Fragment>
          )}
          {!isProcessing && !isUserLogged && upcomingEvents && (
            <section className={"calendar-events-row"}>
              <EventsList
                items={upcomingEvents}
                title={"Upcoming events"}
                isCard={true}
                verticalScroll={true}
                notLoggedUser={true}
                isProcessing={isProcessing}
              ></EventsList>
            </section>
          )}
        </div>
      </div>
    </Navbar>
  );
};

export default Calendar;
