import "./ContextMenu.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import { Button } from "@Components/index";

type ContextMenuItemProps = {
  text: string;
  type: "button" | "link";
  action: any;
};

interface ContextMenuProps {
  backgroundColor?: string;
  items: ContextMenuItemProps[];
}

const ContextMenuItem = ({ text, type, action }: ContextMenuItemProps) => {
  let MenuItemComponent: any = <div>text</div>;
  switch (type) {
    case "button":
      MenuItemComponent = <Button text={text} onClick={action} />;
      break;
    default:
      break;
  }
  return MenuItemComponent;
};

const ContextMenu = ({ backgroundColor, items }: ContextMenuProps) => {
  return (
    <div className={`context-menu`} style={{ backgroundColor }}>
      {items?.map((item, index) => {
        return (
          <div
            key={`context_menu_item${index}`}
            className={"context-menu-item"}
          >
            <ContextMenuItem
              text={item.text}
              type={item.type}
              action={item.action}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ContextMenu;
