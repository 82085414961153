import {
  USER_PROFILE_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_LOGOUT,
  USER_REGISTRATION_REQUEST,
  USER_REGISTRATION_SUCCESS,
  USER_REGISTRATION_ERROR,
  USER_REGISTRATION_DETAILS_REQUEST,
  USER_REGISTRATION_DETAILS_SUCCESS,
  USER_REGISTRATION_DETAILS_ERROR,
  SHOW_REGISTRATION_DETAILS,
  USER_CODE_VERIFICATION_REQUEST,
  USER_CODE_VERIFICATION_SUCCESS,
  USER_CODE_VERIFICATION_ERROR,
  USER_SEND_VERIFICATION_EMAIL_REQUEST,
  USER_SEND_VERIFICATION_EMAIL_SUCCESS,
  USER_SEND_VERIFICATION_EMAIL_ERROR,
  REGISTRATION_CLEAR_ERROR,
  USER_REGISTRATION_HIDE_BANNER,
} from "@App/constants/userConstants";
import { User } from "@App/models/user";

export interface UserState {
  loading?: boolean;
  error?: string;
  userInfo: User | null;
}

export interface RegistrationState {
  userId: string;
  userVerificationCode: string;
  isProcessing?: boolean;
  showRegistrationDetailsResultBanner?: boolean;
  showRegistrationDetails?: boolean;
  registrationCompleted: boolean;
  isCodeVerificationInProgress: boolean;
  isCodeVerificationSuccess: boolean;
  isResendVerificationInProgress: boolean;
  isReSendVerificationSuccess: boolean;
  registrationError?: any;
}

interface UserAction {
  type: string;
  payload?: any;
}

export const userProfileReducer = (
  state: UserState = { userInfo: null },
  action: UserAction
) => {
  let currentState;
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      currentState = { loading: true };
      break;
    case USER_PROFILE_SUCCESS:
      currentState = { loading: false, userInfo: action.payload };
      break;
    case USER_PROFILE_FAIL:
      currentState = { loading: false, error: action.payload };
      break;
    case USER_LOGOUT:
      currentState = {};
      break;

    default:
      currentState = state;
      break;
  }

  return currentState;
};

//TODO: Because Registration is getting bigger, we can create a Registration Reducer?
export const userRegistrationReducer = (
  state: RegistrationState = {
    userId: "",
    userVerificationCode: "",
    isProcessing: false,
    showRegistrationDetails: false,
    registrationCompleted: false,
    isCodeVerificationInProgress: false,
    isCodeVerificationSuccess: false,
    isResendVerificationInProgress: false,
    isReSendVerificationSuccess: false,
  },
  action: UserAction
) => {
  let currentState;
  switch (action.type) {
    case USER_REGISTRATION_REQUEST:
      currentState = { isProcessing: true };
      break;
    case USER_REGISTRATION_SUCCESS:
      currentState = {
        isProcessing: false,
        showRegistrationDetails: true,
        userId: action.payload.id,
        userVerificationCode: action.payload.userVerificationCode,
      };
      break;
    case USER_REGISTRATION_ERROR:
      currentState = {
        isProcessing: false,
        showRegistrationDetails: false,
        registrationError: action.payload,
      };
      break;
    case USER_REGISTRATION_DETAILS_REQUEST:
      currentState = { isProcessing: true };
      break;
    case USER_REGISTRATION_DETAILS_SUCCESS:
      currentState = {
        isProcessing: false,
        showRegistrationDetailsResultBanner: true,
        showRegistrationDetails: false,
        registrationCompleted: true,
      };
      break;
    case USER_REGISTRATION_DETAILS_ERROR:
      currentState = {
        isProcessing: false,
        showRegistrationDetails: true,
      };
      break;
    case SHOW_REGISTRATION_DETAILS:
      currentState = {
        isProcessing: false,
        showRegistrationDetails: true,
        userId: action.payload.id,
        userVerificationCode: action.payload.userVerificationCode,
      };
      break;
    case USER_CODE_VERIFICATION_REQUEST:
      currentState = {
        isCodeVerificationInProgress: true,
      };
      break;
    case USER_CODE_VERIFICATION_SUCCESS:
      currentState = {
        isCodeVerificationInProgress: false,
        isCodeVerificationSuccess: true,
      };
      break;
    case USER_CODE_VERIFICATION_ERROR:
      currentState = {
        isCodeVerificationInProgress: false,
        isCodeVerificationSuccess: false,
      };
      break;
    case USER_SEND_VERIFICATION_EMAIL_REQUEST:
      currentState = {
        isResendVerificationInProgress: true,
      };
      break;
    case USER_SEND_VERIFICATION_EMAIL_SUCCESS:
      currentState = {
        isResendVerificationInProgress: false,
        isReSendVerificationSuccess: true,
      };
      break;
    case USER_SEND_VERIFICATION_EMAIL_ERROR:
      currentState = {
        isResendVerificationInProgress: false,
        isReSendVerificationSuccess: false,
      };
      break;
    case REGISTRATION_CLEAR_ERROR:
      currentState = {
        registrationError: null,
      };
      break;
    case USER_REGISTRATION_HIDE_BANNER:
      currentState = {
        isProcessing: false,
        showRegistrationDetails: false,
      };
      break;
    default:
      currentState = state;
      break;
  }
  return currentState;
};
