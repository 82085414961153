import "./ActionBanner.scss";

interface ActionBannerBannerProps {
  text: string;
  noUnderline?: boolean;
  actionLinkText?: string;
  onClick?: () => void;
  actionClick?: () => void;
}

const ActionBanner = ({
  text,
  noUnderline,
  actionLinkText,
  onClick,
  actionClick,
}: ActionBannerBannerProps) => {
  return (
    <div className={"action-banner"}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 15H13.5V17H11.5V15ZM11.5 7H13.5V13H11.5V7ZM12.5 2C6.97 2 2.5 6.5 2.5 12C2.5 14.6522 3.55357 17.1957 5.42893 19.0711C6.35752 19.9997 7.45991 20.7362 8.67317 21.2388C9.88642 21.7413 11.1868 22 12.5 22C15.1522 22 17.6957 20.9464 19.5711 19.0711C21.4464 17.1957 22.5 14.6522 22.5 12C22.5 10.6868 22.2413 9.38642 21.7388 8.17317C21.2362 6.95991 20.4997 5.85752 19.5711 4.92893C18.6425 4.00035 17.5401 3.26375 16.3268 2.7612C15.1136 2.25866 13.8132 2 12.5 2ZM12.5 20C10.3783 20 8.34344 19.1571 6.84315 17.6569C5.34285 16.1566 4.5 14.1217 4.5 12C4.5 9.87827 5.34285 7.84344 6.84315 6.34315C8.34344 4.84285 10.3783 4 12.5 4C14.6217 4 16.6566 4.84285 18.1569 6.34315C19.6571 7.84344 20.5 9.87827 20.5 12C20.5 14.1217 19.6571 16.1566 18.1569 17.6569C16.6566 19.1571 14.6217 20 12.5 20Z"
          fill="white"
        />
      </svg>

      <label
        className={`action-banner-text ${
          !noUnderline && "action-banner-text-underline"
        } ${onClick && "action-banner-text-link"}`}
        onClick={() => {
          onClick && onClick();
        }}
      >
        {text}
      </label>
      {actionLinkText && (
        <label
          className={`action-banner-text action-banner-text-underline action-banner-text-link`}
          onClick={() => {
            actionClick && actionClick();
          }}
        >
          {actionLinkText}
        </label>
      )}
    </div>
  );
};

export default ActionBanner;
