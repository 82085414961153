import { Auth } from "aws-amplify";
import { useEffect } from "react";

const Logout = () => {
  useEffect(() => {
    Auth.signOut().then(() => {
      window.location.pathname = "";
    });
  }, []);
  return <h1>Logout...</h1>;
};

export default Logout;
