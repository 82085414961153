import "./Breadcrumb.scss";
import BreadcrumbLogic from "./BreadcrumbLogic";
import { Link } from "react-router-dom";
import { Chevron } from "@Components/icons";

interface BreadcrumbProps {
  initialSection?: { text: string; path: string };
  currentSectionName?: string;
}

const Breadcrumb = ({
  initialSection,
  currentSectionName,
}: BreadcrumbProps) => {
  const { pathSections, pathSectionsTotal } = BreadcrumbLogic();
  return (
    <div className={"breadcumb-container"}>
      {initialSection && (
        <div>
          <Link to={`../${initialSection.path}`}>{initialSection.text}</Link>
          <span className={"breadcumb-indicator"}>
            <Chevron size={14} color={"#212529"} />
          </span>
        </div>
      )}
      {pathSections.map((pathSection, index) => {
        const section = pathSection.replace("-", " ");
        return (
          <div key={`breadcrumb_section_${index}`}>
            {index > 1 && (
              <span className={"breadcumb-indicator"}>
                <Chevron size={14} color={"#212529"} />
              </span>
            )}
            {pathSection !== pathSections[pathSectionsTotal] ? (
              <Link to={`../${pathSection}`}>{section}</Link>
            ) : (
              <label>{currentSectionName ?? section}</label>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
