import { MagnifyingGlass } from "@Components/icons";
import { useState } from "react";
import "./SearchInput.scss";

interface SearchInputProps {
  showMagnifier?: boolean;
  typeDelayMS?: number;
  onSearch: (text: string) => void;
}

let searchWaiting: any = null;

const SearchInput = ({
  showMagnifier = true,
  typeDelayMS = 500,
  onSearch,
}: SearchInputProps) => {
  const [searchValue, setSearchValue] = useState(String);
  const [hideMagnifierIcon, setHideMagnifierIcon] = useState(false);
  return (
    <div className={"search-input-container"}>
      {showMagnifier && !hideMagnifierIcon && (
        <div className={"search-input-container-icon"}>
          <MagnifyingGlass size={15} />
        </div>
      )}
      <input
        className={"search-input-container-input"}
        type={"search"}
        value={searchValue}
        onChange={(event) => {
          const textValue = event.currentTarget.value;
          if (searchWaiting) {
            clearTimeout(searchWaiting);
          }
          setSearchValue(textValue);

          searchWaiting = setTimeout(() => {
            searchWaiting = null;
            onSearch(textValue.trim());
          }, typeDelayMS);
        }}
        onFocus={() => {
          setHideMagnifierIcon(true);
        }}
        onBlur={() => {
          if (searchValue.length === 0) {
            setHideMagnifierIcon(false);
          }
        }}
      />
    </div>
  );
};

export default SearchInput;
