import moment from "moment";

export const PasswordRegx =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,}$/;

export const EmailRegx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

//https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s14.html
export const ZipRegx = /^[0-9]{5}(?:-[0-9]{4})?$/;

export const customEventSubscribe = (eventName: string, listener: any) => {
  document.addEventListener(eventName, listener);
};

export const customEventUnsubscribe = (eventName: string, listener: any) => {
  document.removeEventListener(eventName, listener);
};

export const customEventPublish = (eventName: string, data: any) => {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
};

export const getTime = (dateString: string) => {
  const date = new Date(dateString);
  return `${date.getUTCHours()}:${date.getUTCMinutes()}`;
};

export const monthName = ({
  abbreviation,
  month,
}: {
  abbreviation?: boolean;
  month: number;
}) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return abbreviation ? months[month].slice(0, 3) : months[month];
};

export const isSameDay = (dateA: string, dateB: string): boolean => {
  return moment(dateA).format("YY/MM/DD") === moment(dateB).format("YY/MM/DD");
};

export const isSameMonth = (dateA: Date, dateB: Date): boolean => {
  return (
    `${dateA.getFullYear()}-${dateB.getMonth()}` ===
    `${dateB.getFullYear()}-${dateB.getMonth()}`
  );
};
