import "./Registration.scss";
import { Button, Loader, TextInput } from "@Components/index";
import useRegistrationLogic from "./RegistrationLogic";
import { RegistrationProps } from "./RegistrationProps";

const Registration = (componentProps: RegistrationProps) => {
  const {
    firstname,
    lastname,
    email,
    password,
    passwordConfirmation,
    validFirstname,
    validLastname,
    validEmail,
    validPassword,
    validPasswordConfirmation,
    validForm,
    isPasswordResetProcessing,
    errorMessage,
    // validFormSso,
    setFirstname,
    setlastname,
    setEmail,
    setPassword,
    setPasswordConfirmation,
    onRegisterUser,
    // handleSocialButtonClick,
  } = useRegistrationLogic(componentProps);
  return (
    <div className={"registration"}>
      <div className={"registration-form"}>
        <header className={"registration-form-header"}>
          <label className={"registration-title"}>
            {/* TODO: Use community Info data for the name, or config, etc */}
            Welcome to Lakewood Ranch!
          </label>
          <label className={"registration-subtitle"}>
            Let´s get started. Please enter your details
          </label>
          {(componentProps.duplicateAccountError || !!errorMessage) && (
            <div className={"registration-error-banner"}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={"registration-error-banner-icon"}
              >
                <path
                  d="M16.0002 7.98669L26.0402 25.3334H5.96016L16.0002 7.98669ZM16.0002 2.66669L1.3335 28H30.6668L16.0002 2.66669ZM17.3335 21.3334H14.6668V24H17.3335V21.3334ZM17.3335 13.3334H14.6668V18.6667H17.3335V13.3334Z"
                  fill="#FA3E3E"
                />
              </svg>
              <div>
                <div>
                  {errorMessage
                    ? errorMessage
                    : "This email is already associated with a registered account."}
                </div>
                {componentProps.duplicateAccountError && (
                  <div>
                    {`Please `}
                    <label
                      className={"registration-error-banner-link"}
                      onClick={() => {
                        alert("TBD: Close and show login");
                      }}
                    >
                      {`Log In`}
                    </label>
                    {` to continue.`}
                  </div>
                )}
              </div>
            </div>
          )}
        </header>
        <section className={"registration-form-fields"}>
          {!componentProps.userData && (
            <div>
              <TextInput
                type={"text"}
                label={"First Name"}
                placeholder={"Enter First Name"}
                maxLength={50}
                withMargin
                required
                value={firstname}
                onChange={setFirstname}
                isValid={validFirstname}
                errorMessage={"Please enter your first name"}
              />

              <TextInput
                type={"text"}
                label={"Last Name"}
                placeholder={"Enter Last Name"}
                maxLength={50}
                withMargin
                required
                value={lastname}
                onChange={setlastname}
                isValid={validLastname}
                errorMessage={"Please enter your last name"}
              />
            </div>
          )}

          <TextInput
            type={"email"}
            label={"Email"}
            placeholder={"Enter Email"}
            maxLength={100}
            withMargin
            required
            value={email}
            onChange={setEmail}
            isValid={validEmail}
            errorMessage={"Please enter a valid Email address"}
            isDisabled={!!componentProps.userData}
          />

          <TextInput
            type={"password"}
            label={"Password"}
            maxLength={50}
            withMargin
            required
            value={password}
            onChange={setPassword}
            isValid={validPassword}
            errorMessage={
              "The password must contain at least 8 characters, upper and lowercase letters, numerical value and special character."
            }
          />

          <TextInput
            type={"password"}
            label={"Repeat Password"}
            maxLength={50}
            withMargin
            required
            value={passwordConfirmation}
            onChange={setPasswordConfirmation}
            isValid={validPasswordConfirmation}
            errorMessage={
              "Passwords should match and must contain at least 8 characters, upper and lowercase letters, numerical value and special character."
            }
          />
          <div className={"registration-create-account"}>
            <Button
              text={"Create an account"}
              onClick={() => {
                onRegisterUser(componentProps.userData);
              }}
              isDisabled={!validForm || isPasswordResetProcessing}
            />
          </div>
          {/* <div className={"registration-optional-social-text"}>
            Or Sign in with
          </div>
          <div className={"registration-social-buttons"}>
            <SocialButton
              text={"Apple"}
              isApple={true}
              isDisabled={!validFormSso}
              onClick={() => {
                handleSocialButtonClick({ isGoogle: false });
              }}
            />

            <SocialButton
              text={"Google"}
              isGoogle={true}
              isDisabled={!validFormSso}
              onClick={() => {
                handleSocialButtonClick({ isGoogle: true });
              }}
            />
          </div> */}
        </section>
      </div>
      <div className={"registration-right-content"}>
        <div
          className={"registration-image"}
          style={{
            backgroundImage: `url(${require("@Assets/images/lwr_registration.png")})`,
          }}
        >
          <Button
            type={"close"}
            onClick={() => {
              componentProps.onClose && componentProps.onClose();
            }}
          />
        </div>
      </div>
      {isPasswordResetProcessing && (
        <Loader text={"Processing, please wait"} modal />
      )}
    </div>
  );
};

export default Registration;
