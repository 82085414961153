import { useState } from "react";
import "./Checkbox.scss";

interface CheckboxProps {
  text: string;
  defaultChecked?: boolean;
  onChange: (value: boolean) => void;
}

const Checkbox = ({ text, defaultChecked, onChange }: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(defaultChecked ?? false);
  return (
    <div>
      <label className="checkbox">
        {text}
        <input
          type={"checkbox"}
          checked={isChecked}
          onChange={() => {
            setIsChecked(!isChecked);
            onChange(!isChecked);
          }}
        />

        <span className={"checkmark"}></span>
      </label>
    </div>
  );
};

export default Checkbox;
