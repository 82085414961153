import "./EventDetails.scss";
import { Navbar } from "@Layouts/index";
import EventDetailsLogic from "./EventDetailsLogic";
import {
  Breadcrumb,
  Button,
  ImageCarousel,
  Loader,
  Tag,
} from "@Components/index";
import { Heart } from "@Components/icons";
import { handleNotLoggedUserLogin } from "@App/store/actions/eventActions";

const EventDetails = () => {
  const {
    isUserLogged,
    currentEvent,
    isFavPreferenceProcessing,
    isRsvpPreferenceProcessing,
    getEventDate,
    getEventTime,
    getEventCategories,
    updateEventPreference,
  } = EventDetailsLogic();
  return (
    <Navbar>
      {!currentEvent ? (
        <Loader modal text={"Loading event details"} />
      ) : (
        <div className={"event-details-container"}>
          <div className={"event-details-breadcrumb"}>
            <Breadcrumb
              initialSection={{ text: "Home", path: "/" }}
              currentSectionName={currentEvent.title}
            />
          </div>
          <div className={"event-details-text event-details-detail-wrapper"}>
            <div className={"event-details-name"}>{currentEvent.title}</div>
            <div className={"event-details-datetime"}>{getEventDate()}</div>
            <div>{getEventTime()}</div>
            {!currentEvent.isAdminEvent && (
              <ImageCarousel
                slides={
                  currentEvent.images?.length > 0
                    ? Array.from(
                        currentEvent.images.map((imageItem) => {
                          return imageItem.url.replace(" ", "%20"); //update urls that have white space(s)
                        })
                      )
                    : [
                        require("@Assets/images/event_no_image_placeholder.jpeg"),
                      ]
                }
              />
            )}

            <div className={"event-details-bottom-content"}>
              <div className={"event-details-bottom-content-left"}>
                <div
                  className={"event-details-description"}
                  dangerouslySetInnerHTML={{
                    __html: currentEvent.description ?? "",
                  }}
                ></div>
                <div className={"event-details-categories"}>
                  {getEventCategories().map((eventCategory, index) => {
                    return (
                      <div
                        key={`event_category_${index}`}
                        className={"event-details-category"}
                      >
                        <Tag text={eventCategory} />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className={`event-details-bottom-content-right ${
                  isUserLogged
                    ? "event-details-bottom-content-right-logged"
                    : ""
                }`}
              >
                {!currentEvent.isAdminEvent && isUserLogged && (
                  <button
                    className={"event-details-fav"}
                    disabled={isFavPreferenceProcessing}
                    style={{ marginTop: isFavPreferenceProcessing ? 5 : 0 }}
                    onClick={() => {
                      updateEventPreference(
                        "isFavorite",
                        !currentEvent.user?.isFavorite
                      );
                    }}
                  >
                    {isFavPreferenceProcessing ? (
                      <Loader
                        color={"silver"}
                        horizontallyCentered
                        width="20"
                        height="10"
                      />
                    ) : (
                      <Heart isActive={currentEvent.user?.isFavorite} />
                    )}
                  </button>
                )}

                {!currentEvent.isAdminEvent && currentEvent.isRsvpEnabled && (
                  <div className={"event-details-rsvp"}>
                    {!currentEvent.user?.isRSVPd ? (
                      isRsvpPreferenceProcessing ? (
                        <Loader color={"silver"} horizontallyCentered />
                      ) : (
                        <Button
                          text={isUserLogged ? "RSVP" : "Login to RSVP"}
                          onClick={() => {
                            if (isUserLogged) {
                              updateEventPreference(
                                "isRSVPd",
                                !currentEvent.user?.isRSVPd
                              );
                            } else {
                              handleNotLoggedUserLogin(currentEvent);
                            }
                          }}
                        />
                      )
                    ) : isRsvpPreferenceProcessing ? (
                      <Loader color={"silver"} horizontallyCentered />
                    ) : (
                      <Button
                        text={"Attending"}
                        isSecondary={true}
                        onClick={() => {
                          if (isUserLogged) {
                            updateEventPreference("isRSVPd", false);
                          }
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Navbar>
  );
};

export default EventDetails;
