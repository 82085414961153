export const navbarLinks = [
  { text: "home", route: "/" },
  // { text: "your community", route: "/" },
  // { text: "calendar", route: "/Calendar" },
  // { text: "book & reserve", route: "/" },
  // { text: "business & memberships", route: "/" },
  // { text: "community map", route: "/" },
  // { text: "resident information", route: "/" },
  // { text: "loyalty", route: "/login" },
  // { text: "my account", route: "/error" },
];

export const USStates = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];

export const notifications = [
  {
    id: "642dba234613a54fa55a0a9a",
    title: "Broken Lights",
    body: "There are reports of broken public lights near 13th St. & Liberty St.",
    read: false,
    category: "Community Alert",
    timestamp: "2023-04-27T18:30:00Z",
  },
  {
    id: "642dba574882a54fa55a0a9a",
    title: "You've just earned 20 points.",
    body: "Visit the Loyalty Reward program to change them.",
    read: true,
    timestamp: "2023-04-27T17:30:00Z",
  },
  {
    id: "642dba768112a54fa55a0a9a",
    title: "Pool Closed",
    body: "Please note that the Pool will be closed on Wed., April 12th all day due to maintenance. <br><u>Location: Event Center</u>",
    read: true,
    timestamp: "2023-04-27T16:30:00Z",
  },
  {
    id: "642dba345980a54fa55a0a9a",
    title: "Invitation",
    body: "Please note that the Pool will be closed on Wed., April 12th all day due to maintenance.",
    read: true,
    category: "Community Alert",
    timestamp: "2023-04-27T15:30:00Z",
  },
  {
    id: "642dba000341a54fa55a0a9a",
    title: "Invitation",
    body: "Please note that the Pool will be closed on Wed., April 12th all day due to maintenance.",
    read: true,
    category: "Community Alert",
    timestamp: "2023-04-27T14:30:00Z",
  },
  {
    id: "642dba574882a54fa33a0a9a",
    title: "You've just earned 50 points",
    body: "Visit the Loyalty Reward program to change them.",
    read: true,
    timestamp: "2023-04-27T13:30:00Z",
  },
];
