import "./ImageCarousel.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

interface ImageCarouselProps {
  slides: string[];
  onChange?: (index: number) => void;
  onClickItem?: (index: number) => void;
  onClickThumb?: (index: number) => void;
  swipeable?: boolean;
  emulateTouch?: boolean;
  showIndicators?: boolean;
  showStatus?: boolean;
  showThumbs?: boolean;
  infiniteLoop?: boolean;
  useKeyboardArrows?: boolean;
}

const ImageCarousel = ({
  slides,
  onChange,
  onClickItem,
  onClickThumb,
  swipeable = true,
  emulateTouch = true,
  showIndicators = false,
  showStatus = false,
  showThumbs = false,
  infiniteLoop = true,
  useKeyboardArrows = true,
}: ImageCarouselProps) => {
  return (
    <div className={"image-carousel"}>
      <Carousel
        showArrows={true}
        onChange={(index) => {
          onChange && onChange(index);
        }}
        onClickItem={(index, item) => {
          onClickItem && onClickItem(index);
        }}
        onClickThumb={(index, item) => {
          onClickThumb && onClickThumb(index);
        }}
        swipeable={swipeable}
        emulateTouch={emulateTouch}
        showIndicators={showIndicators}
        showStatus={showStatus}
        showThumbs={showThumbs}
        infiniteLoop={infiniteLoop}
        useKeyboardArrows={useKeyboardArrows}
      >
        {slides.map((slide, index) => {
          return (
            <div
              key={`carousel_slide${index}`}
              className={"image-carousel-slide"}
              style={{
                backgroundImage: `url(${slide})`,
              }}
            ></div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
