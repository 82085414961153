import UserSession from "@App/auth/userSession";
import { config } from "@App/config/config";
import axios, { AxiosResponse } from "axios";
import { NotificationsData } from "./requests/notifications";

type GetNotificationsProps = {
  page?: number;
  pageSize?: number;
};

// TODO: Remove hardcoded userId
export const getNotifications = async ({
  page = 1,
  pageSize = 10,
}: GetNotificationsProps) => {
  const response: AxiosResponse<NotificationsData, any> = await axios.get(
    `${config.backendDomain}/Notifications/User/63e14f86bb7fdbc2303a9110/Get?pageSize=${pageSize}&page=${page}`,
    {
      headers: {
        ...(UserSession.accessToken && {
          Authorization: `Bearer ${UserSession.accessToken}`,
        }),
        "cbp-TenantId": config.cbpTenantId,
      },
    }
  );

  return response;
};
