import { useEffect, useState } from "react";
import moment from "moment";
import { EventsListProps } from "./EventListProps";

const EventsListLogic = ({
  items,
  isCard,
  filter,
  dateFilter,
  selectedTag,
  searchText,
}: EventsListProps) => {
  const [showEventsLeftScrollButton, setShowEventsLeftScrollButton] =
    useState(false);
  const [showEventsRightScrollButton, setShowEventsRightScrollButton] =
    useState(false);
  let ignoreAttending = false;
  let eventsRowType = isCard ? "favorites" : "";
  const hasSearchText = searchText && searchText?.length > 0;
  let filteredEvents: any = hasSearchText ? [] : items;

  //TODO: We are going to include some filters in the Search request.
  if (searchText && searchText?.length > 0) {
    const searchTextLowerCase = searchText.toLowerCase();
    filteredEvents = items?.filter((item) => {
      if (
        item.title.toLowerCase().indexOf(searchTextLowerCase) > -1 ||
        item.venue.toLowerCase().indexOf(searchTextLowerCase) > -1
      ) {
        return item;
      }
      return false;
    });
  }

  switch (filter) {
    case "favorites":
      filteredEvents = filteredEvents?.filter(
        (item: any) => item.user?.isFavorite
      );
      ignoreAttending = true;
      break;
    case "rsvp":
      filteredEvents = filteredEvents?.filter(
        (item: any) => item.user?.isRSVPd
      );
      eventsRowType = "rsvp";
      break;
    default:
      break;
  }

  if (dateFilter && filteredEvents) {
    filteredEvents = filteredEvents?.filter(
      (item: any) =>
        moment(item.startDate).get("D") === moment(dateFilter).get("D")
    );
  } else if (selectedTag) {
    filteredEvents = filteredEvents?.filter(
      (item: any) => item.categoryIds?.indexOf(selectedTag) > -1
    );
  }

  useEffect(() => {
    const filterHtmlElement = document.getElementById(
      `events-list-wrapper-${eventsRowType}`
    );

    if (filterHtmlElement) {
      setShowEventsRightScrollButton(
        filterHtmlElement.scrollWidth !== filterHtmlElement.clientWidth
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredEvents]);

  return {
    filteredEvents,
    eventsRowType,
    ignoreAttending,
    showEventsLeftScrollButton,
    showEventsRightScrollButton,
    setShowEventsLeftScrollButton,
  };
};

export default EventsListLogic;
