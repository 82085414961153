import "./ResultBanner.scss";

interface ResultBannerProps {
  text: string;
  onClose: () => void;
}

const ResultBanner = ({ text, onClose }: ResultBannerProps) => {
  return (
    <div className={"result-banner"}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM14.59 5.58L8 12.17L5.41 9.59L4 11L8 15L16 7L14.59 5.58Z"
          fill="#34B927"
        />
      </svg>

      <div className={"result-banner-text"}>{text}</div>
      <div className={"result-banner-close "} onClick={onClose}>
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.17495 5.99999L10.7583 2.42499C11.0851 2.09822 11.0851 1.56843 10.7583 1.24166C10.4315 0.91489 9.90172 0.91489 9.57495 1.24166L5.99995 4.82499L2.42495 1.24166C2.09818 0.91489 1.56838 0.91489 1.24162 1.24166C0.914847 1.56843 0.914847 2.09822 1.24162 2.42499L4.82495 5.99999L1.24162 9.57499C1.08386 9.73146 0.995117 9.94446 0.995117 10.1667C0.995117 10.3889 1.08386 10.6019 1.24162 10.7583C1.39809 10.9161 1.61108 11.0048 1.83328 11.0048C2.05548 11.0048 2.26848 10.9161 2.42495 10.7583L5.99995 7.17499L9.57495 10.7583C9.73142 10.9161 9.94442 11.0048 10.1666 11.0048C10.3888 11.0048 10.6018 10.9161 10.7583 10.7583C10.916 10.6019 11.0048 10.3889 11.0048 10.1667C11.0048 9.94446 10.916 9.73146 10.7583 9.57499L7.17495 5.99999Z"
            fill="#32373F"
          />
        </svg>
      </div>
    </div>
  );
};

export default ResultBanner;
