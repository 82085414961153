import { SHOW_RESULT_BANNER } from "@App/constants/appConstants";
import {
  getEventDetailsData,
  getEventsList,
  updateEventUserPreferences,
} from "@App/store/actions/eventActions";
import { EventCategoriesState } from "@App/store/reducers/eventReducer";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { customEventPublish, isSameDay, monthName } from "@Utils/utils";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Event } from "@App/models/event";

const EventDetailsLogic = () => {
  const [isUserLogged, setIsUserLogged] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<Event | null>();
  const [isFavPreferenceProcessing, setIsFavPreferenceProcessing] =
    useState(false);
  const [isRsvpPreferenceProcessing, setIsRsvpPreferenceProcessing] =
    useState(false);
  const dispatch = useDispatch();

  const eventCategoriesStore = useSelector<RootState, EventCategoriesState>(
    (state) => state.eventCategories
  );
  const { eventCategories } = eventCategoriesStore;

  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile
  );
  const { userInfo } = userData;

  useEffect(() => {
    if (userData.userInfo?.firstName) {
      setIsUserLogged(true);
    }
    getEventDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const getEventDetails = () => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const eventId = searchParams.get("id");
    if (eventId) {
      getEventDetailsData(eventId).then((response) => {
        if (response) {
          setCurrentEvent(response ?? null);
        }
        setIsRsvpPreferenceProcessing(false);
        setIsFavPreferenceProcessing(false);
      });
    }
  };

  const getDatetimeValue = (date: Moment) => {
    const month = monthName({
      month: date.get("M"),
      abbreviation: true,
    });
    return `${date.format(`ddd D [${month}]`)}`;
  };

  const getEventDate = () => {
    if (currentEvent) {
      return `${getDatetimeValue(moment((currentEvent as any)?.startDate))}${
        !isSameDay(currentEvent.startDate, currentEvent.endDate)
          ? ` - ${getDatetimeValue(moment((currentEvent as any)?.endDate))}`
          : ""
      }`;
    }
  };

  const getEventTime = () => {
    let startTime: string = "";
    let endTime: string = "";
    if (currentEvent) {
      startTime = moment(currentEvent.startDate)
        .format("h:mm A")
        .replace(":00", "");
      endTime = moment(currentEvent.endDate)
        .format("h:mm A")
        .replace(":00", "");
    }
    return `${startTime} - ${endTime}`;
  };

  const getEventCategories = () => {
    let eventCategoriesData: string[] = [];

    eventCategories?.forEach((eventCategory) => {
      if (
        currentEvent?.categoryIds &&
        currentEvent.categoryIds.indexOf(eventCategory.id) > -1
      ) {
        eventCategoriesData.push(eventCategory.name);
      }
    });

    return eventCategoriesData;
  };

  const updateEventPreference = (
    preferenceName: string,
    preferenceValue: boolean
  ) => {
    if (userInfo && currentEvent) {
      if (preferenceName === "isRSVPd") {
        setIsRsvpPreferenceProcessing(true);
      } else {
        setIsFavPreferenceProcessing(true);
      }
      updateEventUserPreferences({
        eventId: currentEvent.id,
        userId: userInfo.id,
        preference: { [preferenceName]: preferenceValue },
      }).then((response) => {
        if (response.isSuccess) {
          if (preferenceValue) {
            customEventPublish(
              SHOW_RESULT_BANNER,
              `You've ${
                preferenceName === "isRSVPd" ? "RSVP" : "Favorited"
              } for this event!`
            );
          }
          getEventDetails();
          dispatch(
            getEventsList({
              startDate: new Date(),
              endDate: new Date(),
              isRefresh: true,
              isUpcoming: true,
            }) as any
          );
        }
      });
    }
  };

  return {
    isUserLogged,
    currentEvent,
    isFavPreferenceProcessing,
    isRsvpPreferenceProcessing,
    getEventDate,
    getEventTime,
    getEventCategories,
    updateEventPreference,
  };
};

export default EventDetailsLogic;
