import { CLOSE_INFORMATION_MODAL } from "@App/constants/appConstants";
import { Button } from "@Components/index";
import { customEventPublish } from "@Utils/utils";
import "./ProfileUpdate.scss";

const ProfileUpdate = () => {
  return (
    <div className={"user-details-information-modal"}>
      <div className={"user-details-information-modal-logo"}>
        <img src={require("@Assets/images/community_logo_icon.png")} alt={""} />
      </div>
      <div className={"user-details-information-modal-text"}>
        {`To change your profile information please email info@cecilianpartners.com
     for further assistance.`}
      </div>
      <div className={"user-details-information-modal-buttons"}>
        <Button
          text={"Cancel"}
          onClick={() => {
            customEventPublish(CLOSE_INFORMATION_MODAL, {});
          }}
          isSecondary
        />
        <Button
          text={"Email Us"}
          onClick={() => {
            window.location.href =
              "mailto:info@cecilianpartners.com?subject=Profile update&body=Hello, I want to update my profile..";
          }}
        />
      </div>
    </div>
  );
};

export default ProfileUpdate;
