import { Tag } from "@Components/index";
import { Trashcan } from "@Components/icons";
import moment from "moment";
import { NotificationItemProps } from "./NotificationItemProps";
import "./NotificationItem.scss";

const NotificationItem = ({
  title,
  bodyText,
  category,
  isRead,
  dateISO,
  isMenuItem = false,
  maxWidth,
}: NotificationItemProps) => {
  const date = moment(dateISO).format("MMMM Do");
  return (
    <div
      className={`notification-item ${
        isRead ? "" : "notification-item__unread"
      }`}
      onClick={() => {
        /* TODO: Mark as read */
      }}
    >
      <div className="notification-item__header">
        <h2 className="notification-item__title">{title}</h2>
        {!isMenuItem && <span className="notification-item__date">{date}</span>}
      </div>
      <div
        style={{ maxWidth: `${maxWidth ? maxWidth : ""}` }}
        className="notification-item__body"
        dangerouslySetInnerHTML={{ __html: bodyText }}
      ></div>
      <div className="notification-item__footer">
        {category && <Tag text={category} size="small" />}
        {!isMenuItem && (
          <button
            className="delete-button"
            onClick={() => alert("Delete notification")}
          >
            <Trashcan color="#C4C4C4" />
          </button>
        )}
      </div>
    </div>
  );
};

export default NotificationItem;
