import { Children } from "react";
import { MenuProps } from "./MenuProps";
import "./Menu.scss";

const Menu = ({
  id,
  backgroundColor,
  children,
  onMouseLeave,
  maxWidth = "342px",
}: MenuProps) => {
  const arrayChildren = Children.toArray(children);
  return (
    <div
      onMouseLeave={onMouseLeave}
      id={id}
      className={`dropdown-menu`}
      style={{ backgroundColor, maxWidth }}
    >
      {Children.map(arrayChildren, (child, index) => {
        return (
          <div
            key={`dropdown-menu_item-${index}`}
            className={"dropdown-menu-item"}
          >
            {child}
          </div>
        );
      })}
    </div>
  );
};

export default Menu;
