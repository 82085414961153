import { IconProps } from "@Components/icons/iconTypes";
import "./MagnifyingGlass.scss";

const MagnifyingGlass = ({ color, size }: IconProps) => {
  return (
    <i
      className={`magnifying-glass-icon fa-solid fa-magnifying-glass`}
      style={{ color: color, fontSize: size }}
    ></i>
  );
};

export default MagnifyingGlass;
