import "./Tag.scss";

interface TagProps {
  text: string;
  itemId?: string;
  fontColor?: string;
  backgroundColor?: string;
  multiple?: boolean;
  isStatusTag?: boolean;
  size?: "large" | "small";
  onClick?: (value: string) => void;
  isSelected?: boolean;
  style?: object;
}
const Tag = ({
  text,
  fontColor = "#32373f",
  backgroundColor = "#dae4f8",
  multiple,
  isStatusTag,
  onClick,
  isSelected,
  itemId,
  style,
  size = "large",
}: TagProps) => {
  if (isSelected) {
    fontColor = "#F1F3F5";
    backgroundColor = "#353b40";
  }
  return (
    <div
      className={`tag ${multiple && "tag-multiple"} ${
        onClick ? "tag-with-action" : ""
      }`}
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick(itemId ?? text);
      }}
      style={style}
    >
      <div
        className={`tag-item ${isStatusTag ? "tag-item-status" : ""} ${size}`}
        style={{ backgroundColor }}
      >
        <span
          className={`tag-text ${isStatusTag ? "tag-text-status" : ""} ${size}`}
          style={{ color: fontColor }}
        >
          {text}
        </span>
      </div>
    </div>
  );
};

export default Tag;
