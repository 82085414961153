import { ThreeDots } from "react-loader-spinner";
import "./Loader.scss";

interface LoaderProps {
  text?: string;
  color?: string;
  width?: string;
  height?: string;
  modal?: boolean;
  className?: string;
  noBackground?: boolean;
  fontColor?: string;
  horizontallyCentered?: boolean;
}
const Loader = ({
  text,
  color = "#FFF",
  width = "50",
  height = "50",
  modal,
  className = "",
  noBackground = false,
  fontColor = "#fff",
  horizontallyCentered,
}: LoaderProps) => {
  return (
    <div
      className={`loader ${modal ? "loader-modal" : ""}  ${
        noBackground ? "loader-modal-no-background" : ""
      } ${className} ${
        horizontallyCentered ? "loader-horizontally-centered" : ""
      }`}
    >
      <ThreeDots
        width={width}
        height={height}
        radius={"9"}
        color={color}
        ariaLabel={"three-dots-loading"}
        wrapperStyle={{}}
        visible={true}
      />

      <div style={{ color: fontColor }}>{text}</div>
    </div>
  );
};

export default Loader;
