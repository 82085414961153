import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "@App/auth/ProtectedRoute";
import {
  Registration,
  Calendar,
  Error,
  Home,
  Logout,
  EmailConfirmation,
  EventDetails,
  Notifications,
} from "@Pages/index";
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import userSession from "@App/auth/userSession";
import { useDispatch } from "react-redux";
import { getUserDetails } from "@App/store/actions/userActions";
import {
  getEventCategoriesData,
  getUpcomingEvents,
} from "@App/store/actions/eventActions";

function App() {
  const [sessionChecked, setSessionChecked] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!sessionChecked) {
      //TODO: TBD where can we get all the enums at the start.
      dispatch(getEventCategoriesData() as any);
      Auth.currentSession()
        .then((session) => {
          userSession.accessToken = session.getAccessToken().getJwtToken();
          Auth.currentUserInfo()?.then((info) => {
            userSession.cognitoUsername = info.username;
            setSessionChecked(true);
            dispatch(getUserDetails() as any);
          });
        })
        .catch((error) => {
          setSessionChecked(true);
        })
        .finally(() => {
          dispatch(getUpcomingEvents() as any);
        });
    }
  }, [sessionChecked, dispatch]);

  if (!sessionChecked) {
    return <p>Checking session, pelase wait...</p>;
  }
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<ProtectedRoute />}>
            {/* Protected Routes here... */}
          </Route>
          {/* Error page */}
          <Route path={"*"} element={<Error />} />
          <Route path={"/"} element={<Home />} />
          <Route path={"/registration"} element={<Registration />} />
          <Route path={"/logout"} element={<Logout />} />
          <Route path={"/emailconfirmation"} element={<EmailConfirmation />} />
          <Route path={"/calendar"} element={<Calendar />} />
          <Route path={"/calendar/event-details"} element={<EventDetails />} />
          <Route path={"/notifications"} element={<Notifications />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
