import { Loader, Modal } from "@Components/index";
import "./TextInput.scss";
import TextInputLogic from "./TextInputLogic";

interface TextInputProps {
  type: "text" | "email" | "password";
  label?: string;
  placeholder?: string;
  maxLength?: number;
  withMargin?: boolean;
  required?: boolean;
  value?: string;
  isValid?: boolean;
  errorMessage?: string;
  isDisabled?: boolean;
  isLoadingContent?: boolean;
  tooltip?: string;
  tooltipPosition?: "left" | "top" | "right" | "bottom";
  informationModalContent?: React.ReactNode;
  onChange: (value: string) => void;
}
const TextInput = ({
  type,
  label,
  placeholder,
  maxLength,
  withMargin,
  required = false,
  value,
  isValid = true,
  errorMessage,
  isDisabled,
  isLoadingContent,
  tooltip,
  tooltipPosition = "left",
  informationModalContent,
  onChange,
}: TextInputProps) => {
  const isPassword = type === "password";
  const {
    isTextVisible,
    showinformationModal,
    setIsTextVisible,
    setShowinformationModal,
  } = TextInputLogic();
  return (
    <div className={`text-input ${withMargin && "text-input-margin"}`}>
      <div className={"text-input-label-row"}>
        <div className={"text-input-label"}>{`${label}${
          required ? "*" : ""
        }`}</div>

        {(!!tooltip || informationModalContent) && (
          <div
            className={"tooltip"}
            onClick={() => {
              setShowinformationModal(true);
            }}
          >
            {!!tooltip && (
              <span className={`tooltiptext tooltip-${tooltipPosition}`}>
                {tooltip}
              </span>
            )}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                fill="#334150"
              />
            </svg>
          </div>
        )}
      </div>

      {isLoadingContent ? (
        <div className={"text-input-field text-input-loader"}>
          <Loader modal={false} color={"silver"} />
        </div>
      ) : (
        <div
          className={`${
            isPassword ? " text-input-field-password-wrapper" : ""
          }`}
        >
          <input
            className={`text-input-field ${
              isPassword && "text-input-field-password"
            }`}
            type={isTextVisible ? "text" : type}
            maxLength={maxLength}
            placeholder={placeholder}
            value={value}
            onChange={(event) => onChange(event.currentTarget.value)}
            disabled={isDisabled}
          />
          {isPassword && (
            <button
              className={"text-input-field-password-button"}
              onClick={() => {
                setIsTextVisible(!isTextVisible);
              }}
            >
              {!isTextVisible ? (
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 6.50001C15.79 6.50001 19.17 8.63001 20.82 12C20.23 13.22 19.4 14.27 18.41 15.12L19.82 16.53C21.21 15.3 22.31 13.76 23 12C21.27 7.61001 17 4.50001 12 4.50001C10.73 4.50001 9.51 4.70001 8.36 5.07001L10.01 6.72001C10.66 6.59001 11.32 6.50001 12 6.50001ZM10.93 7.64001L13 9.71001C13.57 9.96001 14.03 10.42 14.28 10.99L16.35 13.06C16.43 12.72 16.49 12.36 16.49 11.99C16.5 9.51001 14.48 7.50001 12 7.50001C11.63 7.50001 11.28 7.55001 10.93 7.64001ZM2.01 4.37001L4.69 7.05001C3.06 8.33001 1.77 10.03 1 12C2.73 16.39 7 19.5 12 19.5C13.52 19.5 14.98 19.21 16.32 18.68L19.74 22.1L21.15 20.69L3.42 2.95001L2.01 4.37001ZM9.51 11.87L12.12 14.48C12.08 14.49 12.04 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 11.95 9.51 11.92 9.51 11.87V11.87ZM6.11 8.47001L7.86 10.22C7.63 10.77 7.5 11.37 7.5 12C7.5 14.48 9.52 16.5 12 16.5C12.63 16.5 13.23 16.37 13.77 16.14L14.75 17.12C13.87 17.36 12.95 17.5 12 17.5C8.21 17.5 4.83 15.37 3.18 12C3.88 10.57 4.9 9.39001 6.11 8.47001Z"
                    fill="#868E96"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 6C15.79 6 19.17 8.13 20.82 11.5C19.17 14.87 15.79 17 12 17C8.21 17 4.83 14.87 3.18 11.5C4.83 8.13 8.21 6 12 6ZM12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 9C13.38 9 14.5 10.12 14.5 11.5C14.5 12.88 13.38 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 10.12 10.62 9 12 9ZM12 7C9.52 7 7.5 9.02 7.5 11.5C7.5 13.98 9.52 16 12 16C14.48 16 16.5 13.98 16.5 11.5C16.5 9.02 14.48 7 12 7Z"
                    fill="#868E96"
                  />
                </svg>
              )}
            </button>
          )}
        </div>
      )}

      {!isValid && <label className={"text-input-error"}>{errorMessage}</label>}
      <Modal
        isVisible={showinformationModal}
        onClose={() => {
          setShowinformationModal(false);
        }}
        children={informationModalContent}
      />
    </div>
  );
};

export default TextInput;
