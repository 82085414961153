import { Button, Loader, Modal } from "@Components/index";
import "./ResendEmail.scss";
import ResendEmailLogic from "./ResendEmailLogic";

interface ResendEmailProps {
  isVisible: boolean;
  onClose?: () => void;
}

const ResendEmail = ({ isVisible, onClose }: ResendEmailProps) => {
  const { userEmail, isResendEmailProcessing, resendVerificationEmail } =
    ResendEmailLogic(onClose);
  return (
    <Modal
      isVisible={isVisible}
      onClose={() => {
        onClose && onClose();
      }}
      children={
        <div className={"resend-email-modal"}>
          <Button
            text={"Check again and continue"}
            onClick={() => onClose && onClose()}
            type={"close"}
            textColor={"#000"}
          />
          <div className={"resend-email-modal-logo"}>
            <img
              src={require("@Assets/images/community_logo_icon.png")}
              alt={""}
            />
          </div>
          <div className={"resend-email-modal-text resend-email-modal-title"}>
            Verify your Email
          </div>
          <br />
          <div className={"resend-email-modal-text"}>
            {`We have sent an email to ${userEmail}`}
          </div>
          <br />
          <div
            className={"resend-email-modal-text resend-email-modal-description"}
          >
            You need to verify your email to continue. If you have not received
            the verification email, please check your “Spam” or “Bulk Email”
            folder. You can also click the resend button below to have another
            email sent to you.
          </div>
          <br />
          <Button
            text={"Check again and continue"}
            onClick={() => onClose && onClose()}
          />
          <br />
          <div className={"resend-email-modal-link-wrapper"}>
            <button
              className={"resend-email-modal-link"}
              onClick={() => {
                resendVerificationEmail();
              }}
            >
              Resend verification email
            </button>
          </div>
          {isResendEmailProcessing && (
            <Loader
              modal={true}
              text={"Resending the mail to validate you email"}
            />
          )}
        </div>
      }
    />
  );
};

export default ResendEmail;
