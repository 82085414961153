import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@Store/store";
import {
  EventCategoriesState,
  EventsState,
  UpcomingEventsState,
} from "@App/store/reducers/eventReducer";
import { UserState } from "@App/store/reducers/userReducer";
import { useEffect, useState } from "react";
import { getEventsList } from "@App/store/actions/eventActions";
import { EventCategory } from "@App/models/eventCategory";
import { RSVP_LOGIN_FLOW } from "@App/constants/appConstants";

const CalendarLogic = () => {
  const SCROLL_AMOUNT = 300;
  const dispatch = useDispatch();
  const storeData = useSelector<
    RootState,
    {
      storeUser: UserState;
      storeEvents: EventsState;
      storeUpcomingEvents: UpcomingEventsState;
      storeEventCategories: EventCategoriesState;
    }
  >((state) => {
    return {
      storeUser: state.userProfile,
      storeEvents: state.eventsData,
      storeUpcomingEvents: state.upcomingEventsData,
      storeEventCategories: state.eventCategories,
    };
  });
  const { storeUser, storeEvents, storeEventCategories, storeUpcomingEvents } =
    storeData;
  const [selectedDate, setSelectedDate] = useState(null);
  const [calendarCurrentMonth, setCalendarCurrentMonth] = useState(new Date());
  const [selectedCategory, setSelectedCategory] = useState("0");
  const [searchText, setSearchText] = useState(String);
  const [showCategoryLeftScrollButton, setShowCategoryLeftScrollButton] =
    useState(false);
  const [isUserLogged, setIsUserLogged] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [events, setEvents] = useState(storeEvents.events);
  const [upcomingEvents, setUpcomingEvents] = useState(
    storeUpcomingEvents.upcomingEvents
  );
  const [categoryFilters, setCategoryFilters] = useState<
    EventCategory[] | null
  >(null);

  const [showCategoryRightScrollButton, setShowCategoryRightScrollButton] =
    useState(false);

  const handleMonthChange = (date: Date, isRefresh?: boolean) => {
    setShowCategoryLeftScrollButton(false);
    const isSameMonth =
      `${date.getFullYear()}-${date.getMonth()}` ===
      `${calendarCurrentMonth.getFullYear()}-${calendarCurrentMonth.getMonth()}`;

    if (!isSameMonth || isRefresh) {
      setCalendarCurrentMonth(date);
      setIsProcessing(true);
      dispatch(
        getEventsList({
          startDate: date,
          endDate: date,
          isRefresh,
        }) as any
      );
    }
  };

  useEffect(() => {
    //Check if user is doing the RSVP Login (so that is loads the updated events).
    const pendingRSVPEventData = localStorage.getItem(RSVP_LOGIN_FLOW);
    if (!pendingRSVPEventData) {
      handleMonthChange(new Date(), true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filterHtmlElement = document.getElementById(
      "calendar-category-filters"
    );
    if (filterHtmlElement) {
      setShowCategoryRightScrollButton(
        filterHtmlElement.scrollWidth !== filterHtmlElement.clientWidth
      );
    }
  }, [categoryFilters]);

  useEffect(() => {
    if (!isUserLogged && !!storeUser?.userInfo?.firstName) {
      setIsUserLogged(true);
    }

    //Check if user is doing the RSVP Login
    const pendingRSVPEventData = localStorage.getItem(RSVP_LOGIN_FLOW);
    if (pendingRSVPEventData) {
      const eventData = JSON.parse(pendingRSVPEventData);
      const eventDate = new Date(eventData.eventDate);
      handleMonthChange(eventDate, true);
      localStorage.removeItem(RSVP_LOGIN_FLOW);
    }

    setEvents(storeEvents.events);
    setIsProcessing(storeEvents.loading ?? false);

    setUpcomingEvents(storeUpcomingEvents.upcomingEvents);

    setCategoryFilters(
      storeEventCategories.loading ? null : storeEventCategories.eventCategories
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    storeUser,
    storeEvents,
    storeUpcomingEvents,
    storeEventCategories,
    calendarCurrentMonth,
  ]);

  const handleScroll = (isLeft?: boolean) => {
    const filterHtmlElement = document.getElementById(
      "calendar-category-filters"
    );

    if (filterHtmlElement) {
      isLeft
        ? (filterHtmlElement.scrollLeft -= SCROLL_AMOUNT)
        : (filterHtmlElement.scrollLeft += SCROLL_AMOUNT);
      setShowCategoryLeftScrollButton(
        isLeft
          ? filterHtmlElement.scrollLeft - SCROLL_AMOUNT > 0
          : filterHtmlElement.scrollWidth > filterHtmlElement.clientWidth
      );
    }
  };

  return {
    categoryFilters,
    isProcessing,
    isUserLogged,
    selectedDate,
    setSelectedDate,
    handleMonthChange,
    selectedCategory,
    setSelectedCategory,
    events,
    upcomingEvents,
    showCategoryLeftScrollButton,
    setShowCategoryLeftScrollButton,
    showCategoryRightScrollButton,
    searchText,
    setSearchText,
    handleScroll,
  };
};

export default CalendarLogic;
