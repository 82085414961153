import {
  Button,
  Checkbox,
  Dropdown,
  ProfileUpdate,
  TextInput,
} from "@Components/index";
import { USStates } from "@Utils/dummy";
import React from "react";
import "./RegistrationDetails.scss";
import RegistrationDetailsLogic from "./RegistrationDetailsLogic";

interface RegistrationDetailsProps {
  onSave: () => void;
  onClose: () => void;
}

const RegistrationDetails = ({ onSave, onClose }: RegistrationDetailsProps) => {
  const {
    showRegistrationDetails,
    address1,
    address2,
    city,
    zip,
    validAddress1,
    validCity,
    validZip,
    validForm,
    setAddress1,
    setAddress2,
    setState,
    setCity,
    setZip,
    setShowRegistrationDetails,
    setMemberType,
    marketingCommunicationsEnabled,
    setMarketingCommunicationsEnabled,
    saveRegistrationDetails,
    userDataLoaded,
    email,
    setEmail,
    validEmail,
    firstname,
    setFirstname,
    validFirstname,
    lastname,
    setLastname,
    validLastname,
  } = RegistrationDetailsLogic();
  return (
    <div className={"user-details-wrapper"}>
      <Button type={"close"} textColor={"#000"} onClick={onClose} />
      <label className={"user-details-title"}>
        Complete your account setup
      </label>
      <label className={"user-details-subtitle"}>
        Please provide the following details.
      </label>
      <div className={"user-details-top-section"}>
        <div className={"user-details-top-section-row"}>
          <div className={"user-details-row-field"}>
            <TextInput
              type={"text"}
              label={"First Name"}
              placeholder={"Enter First Name"}
              maxLength={50}
              withMargin
              required
              value={firstname}
              onChange={setFirstname}
              isValid={validFirstname}
              errorMessage={"Please enter your first name"}
              isDisabled={!userDataLoaded}
              isLoadingContent={!userDataLoaded}
              // TODO: Community data is needed here for the name, or the entire message?
              informationModalContent={<ProfileUpdate />}
            />
          </div>
          <div className={"user-details-row-field"}>
            <TextInput
              type={"text"}
              label={"Last Name"}
              placeholder={"Enter Last Name"}
              maxLength={50}
              withMargin
              required
              value={lastname}
              onChange={setLastname}
              isValid={validLastname}
              errorMessage={"Please enter your last name"}
              isDisabled={!userDataLoaded}
              isLoadingContent={!userDataLoaded}
            />
          </div>
        </div>
        <div className={"user-details-top-section-row"}>
          <div className={"user-details-row-field"}>
            <TextInput
              type={"email"}
              label={"Email"}
              placeholder={"Enter Email"}
              maxLength={100}
              withMargin
              required
              value={email}
              onChange={setEmail}
              isValid={validEmail}
              errorMessage={"Please enter a valid Email address"}
              isDisabled
              isLoadingContent={!userDataLoaded}
            />
          </div>
          <div className={"user-details-row-field user-details-top-section"}>
            <Dropdown
              onChange={(selectedItem) => {
                setShowRegistrationDetails(selectedItem.value === "1");
                setMemberType(selectedItem.value);
              }}
              items={[
                { label: "Resident", value: "1" },
                { label: "Community member", value: "2" },
              ]}
              label={"Resident or Community member?"}
              tooltip={"TBD"}
            />
          </div>
        </div>
      </div>
      {showRegistrationDetails && (
        <React.Fragment>
          <div className={"user-details-top-section-row"}>
            <div className={"user-details-row-field"}>
              <TextInput
                type={"text"}
                label={"Address 1"}
                onChange={setAddress1}
                required
                placeholder={"Enter your address"}
                maxLength={70}
                withMargin
                value={address1}
                isValid={validAddress1}
                errorMessage={"Please enter your address"}
              />
            </div>
            <div className={"user-details-row-field"}>
              <TextInput
                type={"text"}
                label={"Address 2"}
                onChange={setAddress2}
                placeholder={"Enter your address"}
                maxLength={70}
                withMargin
                value={address2}
              />
            </div>
          </div>
          <div className={"user-details-top-section-row"}>
            <div
              className={"user-details-top-section-row user-details-row-field"}
            >
              <div className={"user-details-row-field"}>
                <TextInput
                  type={"text"}
                  label={"City"}
                  onChange={setCity}
                  required
                  placeholder={"Enter your city"}
                  maxLength={20}
                  value={city}
                  isValid={validCity}
                  errorMessage={"Please enter your city"}
                />
              </div>
              <div className={"user-details-row-field"}>
                <Dropdown
                  label={"State"}
                  items={USStates}
                  required
                  onChange={(item) => {
                    setState(item.value);
                  }}
                />
              </div>
            </div>
            <div className={"user-details-row-field"}>
              <TextInput
                type={"text"}
                label={"Zip"}
                onChange={setZip}
                required
                placeholder={"Enter your zip"}
                maxLength={10}
                withMargin
                value={zip}
                isValid={validZip}
                errorMessage={"Please enter your zip code"}
              />
            </div>
          </div>
        </React.Fragment>
      )}
      <div className={"user-details-bottom-marketing"}>
        <Checkbox
          text={
            //TODO: Community data is needed here for the name, or the entire message?
            "I would like to receive marketing communications and offers from (Community Name) and its selected partners."
          }
          defaultChecked={marketingCommunicationsEnabled}
          onChange={(value) => {
            setMarketingCommunicationsEnabled(value);
          }}
        />
      </div>
      <div className={"user-details-bottom-disclaimer"}>
        By registering for this account and selecting 'Continue' I agree to the{" "}
        <a
          className={"user-details-bottom-disclaimer-link"}
          href="https://google.com"
          target={"_blank"}
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        {` and associated `}
        <a
          className={"user-details-bottom-disclaimer-link"}
          href="https://google.com"
          target={"_blank"}
          rel="noreferrer"
        >
          Terms & Conditions.
        </a>
      </div>
      <div className={"user-details-save-button"}>
        <Button
          text={"Save"}
          onClick={() => {
            saveRegistrationDetails();
            onSave();
          }}
          isDisabled={!validForm}
        />
      </div>
    </div>
  );
};

export default RegistrationDetails;
