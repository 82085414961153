import "./Home.scss";
import { Navbar } from "@Layouts/index";

const Home = () => {
  return (
    <div className={"home-container"}>
      <Navbar>
        <section className={"home-section-wrapper"}>
          <div className={"home-section"}></div>
        </section>
      </Navbar>
    </div>
  );
};

export default Home;
