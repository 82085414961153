export const config = {
  region: "us-east-1",
  userPoolId: "us-east-1_n6VlUhEqk",
  userPoolWebClientId: "ge60a7kd1serf5o83ndtqib3p",
  domain: "cbp-cecilianpartners-dev.auth.us-east-1.amazoncognito.com",
  redirectSignIn: "http://cbp-resident-website-dev.s3-website-us-east-1.amazonaws.com/registration",
  redirectSignOut: "http://cbp-resident-website-dev.s3-website-us-east-1.amazonaws.com",
  googleClientId:
    "791914992291-1iu7qkc5pem6l9h9vik2vmsh3fuen4du.apps.googleusercontent.com",
  appleClientId: "io.customerblueprint.auth-cecilianpartners-dev",
  registrationRedirectUri: "http://cbp-resident-website-dev.s3-website-us-east-1.amazonaws.com",
  defaultRedirectUri: "http://cbp-resident-website-dev.s3-website-us-east-1.amazonaws.com",
  leadSource: "Resident_Website",
  backendDomain: "https://ccsrbpo5wa.execute-api.us-east-1.amazonaws.com/Prod",
  cbpTenantId: "lakewoodranch",
};
