import {
  EVENTS_REQUEST_GET,
  EVENTS_REQUEST_SUCCESS,
  EVENTS_REQUEST_FAIL,
  EVENT_CATEGORIES_REQUEST_GET,
  EVENT_CATEGORIES_REQUEST_SUCCESS,
  EVENT_CATEGORIES_REQUEST_FAIL,
  UPCOMING_EVENTS_REQUEST_GET,
  UPCOMING_EVENTS_REQUEST_FAIL,
  UPCOMING_EVENTS_REQUEST_SUCCESS,
} from "@App/constants/eventConstants";
import {
  getEventCategories,
  getEventDetails,
  getEvents,
  updateEventPreference,
  UpdateEventUserPreferencesProps,
} from "@Api/event";
import { Event } from "@App/models/event";
import { EventCategory } from "@App/models/eventCategory";
import { RSVP_LOGIN_FLOW, SHOW_LOGIN_MODAL } from "@App/constants/appConstants";
import { customEventPublish } from "@Utils/utils";
import moment from "moment";

type GetEventsData = {
  page: number;
  pageSize: number;
  startDate: string;
  endDate?: string;
  isRefresh?: boolean;
  isUpcoming?: boolean;
};
export const getEventsData =
  ({
    page,
    pageSize,
    startDate,
    endDate,
    isRefresh,
    isUpcoming,
  }: GetEventsData) =>
  async (dispatch: any): Promise<void> => {
    let resultTypeSuccess = EVENTS_REQUEST_SUCCESS;
    let resultTypeFail = EVENTS_REQUEST_FAIL;
    try {
      if (!isRefresh) {
        const getType = isUpcoming
          ? UPCOMING_EVENTS_REQUEST_GET
          : EVENTS_REQUEST_GET;
        dispatch({
          type: getType,
        });
      }
      const response = await getEvents({ page, pageSize, startDate, endDate });
      const isSuccess = response.status === 200;
      let eventsData: Event[] = isSuccess ? response.data.items : null;

      if (isSuccess) {
        if (isUpcoming) {
          resultTypeSuccess = UPCOMING_EVENTS_REQUEST_SUCCESS;
        }
      } else {
        if (isUpcoming) {
          resultTypeFail = UPCOMING_EVENTS_REQUEST_FAIL;
        }
      }
      dispatch({
        type: isSuccess ? resultTypeSuccess : resultTypeFail,
        payload: eventsData,
      });
    } catch (error) {
      dispatch({
        type: resultTypeFail,
        payload: `ERROR: ${error}`,
      });
    }
  };

export const getEventDetailsData = async (
  eventId: string
): Promise<Event | null> => {
  try {
    const response = await getEventDetails(eventId);
    return response.data;
  } catch (error) {
    console.log("ERROR: ", error);
    return null;
  }
};

export const getEventCategoriesData =
  () =>
  async (dispatch: any): Promise<void> => {
    try {
      dispatch({
        type: EVENT_CATEGORIES_REQUEST_GET,
      });

      const response = await getEventCategories();
      const isSuccess = response.status === 200;
      let eventCategories: EventCategory[] = isSuccess ? response.data : null;

      dispatch({
        type: isSuccess
          ? EVENT_CATEGORIES_REQUEST_SUCCESS
          : EVENT_CATEGORIES_REQUEST_FAIL,
        payload: eventCategories,
      });
    } catch (error) {
      dispatch({
        type: EVENT_CATEGORIES_REQUEST_FAIL,
        payload: `ERROR: ${error}`,
      });
    }
  };

export const updateEventUserPreferences = async (
  args: UpdateEventUserPreferencesProps
): Promise<any> => {
  try {
    const response = await updateEventPreference(args);
    return { isSuccess: response.status === 204 };
  } catch (error) {
    console.log(`ERROR: ${error}`);
  }
};

export const handleNotLoggedUserLogin = (event: Event) => {
  localStorage.setItem(
    RSVP_LOGIN_FLOW,
    JSON.stringify({ eventId: event.id, eventDate: event.startDate })
  );
  customEventPublish(SHOW_LOGIN_MODAL, {});
};

export const getEventsList =
  ({
    startDate,
    endDate,
    isRefresh,
    isUpcoming,
  }: {
    startDate: Date;
    endDate: Date;
    isRefresh?: boolean;
    isUpcoming?: boolean;
  }) =>
  async (dispatch: any): Promise<void> => {
    const formattedMonth = (
      isUpcoming ? moment(startDate).add(6, "M") : moment(startDate)
    ).format("MM");

    const year = isUpcoming
      ? moment().add(6, "M").get("year")
      : endDate.getFullYear();

    const lastDayOfMonth = new Date(year, Number(formattedMonth), 0);

    const isCurrentMonth =
      moment().format("YYYY/MM") === moment(startDate).format("YYYY/MM");

    let initialMonthDate: string | number =
      isCurrentMonth || isUpcoming ? new Date().getDate() : "01";

    dispatch(
      getEventsData({
        page: 1,
        pageSize: 100,
        startDate: `${startDate.getFullYear()}-${moment(
          isUpcoming ? new Date() : startDate
        ).format("MM")}-${initialMonthDate}`,
        endDate: `${year}-${formattedMonth}-${lastDayOfMonth.getDate()}`,
        isRefresh: isRefresh,
        isUpcoming,
      })
    ) as any;
  };

export const getUpcomingEvents =
  () =>
  async (dispatch: any): Promise<void> => {
    const date = new Date();
    dispatch(
      getEventsList({
        startDate: date,
        endDate: date,
        isUpcoming: true,
      }) as any
    );
  };
