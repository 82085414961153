import { IconProps } from "@Components/icons/iconTypes";
import "./Chevron.scss";

interface ChevronProps extends IconProps {
  isLeft?: boolean;
}

const Chevron = ({ isLeft, color, size }: ChevronProps) => {
  return (
    <i
      className={`chevron-icon fa-solid fa-chevron-${
        isLeft ? "left" : "right"
      }`}
      style={{ color: color, fontSize: size }}
    ></i>
  );
};

export default Chevron;
