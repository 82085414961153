import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RegistrationState, UserState } from "@Store/reducers/userReducer";
import { RootState } from "@Store/store";
import {
  createUserAccount,
  logout,
  validateUserFirstLogin,
} from "@Store/actions/userActions";
import { REGISTRATION_EXISTING_ACCOUNT } from "@App/api/statusCodes";
import {
  REGISTRATION_CLEAR_ERROR,
  SHOW_REGISTRATION_DETAILS,
  USER_REGISTRATION_HIDE_BANNER,
} from "@App/constants/userConstants";
import { customEventSubscribe, customEventUnsubscribe } from "@Utils/utils";
import {
  SHOW_LOGIN_MODAL,
  SHOW_RESULT_BANNER,
} from "@App/constants/appConstants";
import { getNotifications } from "@App/api/notifications";
import { useInfiniteQuery } from "@tanstack/react-query";
import { QUERY_KEY_NOTIFICATIONS } from "@App/constants/queryKeyConstants";

const NavbarLogic = () => {
  const currentRoute = useLocation().pathname;
  const formData = localStorage.getItem("cp-resident-registration-form-data");
  let formDataObject = formData ? JSON.parse(formData) : null;
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
  const [isNotificationsMenuVisible, setIsNotificationsMenuVisible] =
    useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingMessage, setProcessingMessage] = useState(
    "Loading, please wait"
  );
  const [showResultBanner, setShowResultBanner] = useState(false);
  const [resultBannerMessage, setResultBannerMessage] = useState("");
  const [showRegistrationUserDetails, setShowRegistrationUserDetails] =
    useState(false);
  const [isDuplicateAccountError, setIsDuplicateAccountError] = useState(false);

  const [isEmailVerfied, setIsEmailVerfied] = useState(true);
  const [isResidentialStatusProvided, setIsResidentialStatusProvided] =
    useState(true);
  const [showResendEmailModal, setShowResendEmailModal] = useState(false);
  const [isloadingUserData, setIsloadingUserData] = useState(false);
  const [userDataParameters, setUserDataParameters] = useState<any>(null);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>(
    null
  );

  const { data: notificationsData, isLoading: isLoadingNotifications } =
    useInfiniteQuery({
      queryKey: [QUERY_KEY_NOTIFICATIONS],
      queryFn: ({ pageParam }) =>
        getNotifications({ page: pageParam }).then((res) => res.data),
      getNextPageParam: (lastPage) => {
        if (lastPage.isLastPage) {
          return;
        } else {
          return lastPage.page + 1;
        }
      },
    });

  const dispatch = useDispatch();

  const storeData = useSelector<
    RootState,
    {
      userData: UserState;
      registrationData: RegistrationState;
    }
  >((state) => {
    return {
      userData: state.userProfile,
      registrationData: state.userRegistration,
    };
  });

  const { userData, registrationData } = storeData;

  useEffect(() => {
    if (!isloadingUserData) {
      const url = new URL(window.location.href);
      const searchParams = url.searchParams;
      const code = searchParams.get("C");
      const emailEncoded = searchParams.get("E");
      if (code && emailEncoded) {
        window.history.replaceState(null, "", window.location.pathname);
        const email = decodeURI(emailEncoded);
        setIsloadingUserData(true);
        validateUserFirstLogin({ email, userVerificationCode: code }).then(
          (response) => {
            setIsloadingUserData(false);
            if (response.email) {
              setUserDataParameters({
                code,
                email: response.email,
                firstname: response.firstName,
                lastname: response.lastName,
              });
              setShowRegistration(true);
            } else {
              setLoginErrorMessage(
                "You previously already validated your account with a password. Please log in below to continue or select 'Forgot Password'."
              );
              setShowLogin(true);
              console.log(
                `SHOW ${
                  response.alreadyVerified ? "ALREADY USED" : "INVALID"
                } ERROR`
              );
            }
          }
        );
      }
    }

    customEventSubscribe(SHOW_LOGIN_MODAL, () => setShowLogin(true));
    customEventSubscribe(SHOW_RESULT_BANNER, showCustomResultBanner);
    return () => {
      customEventUnsubscribe(SHOW_LOGIN_MODAL, () => setShowLogin(true));
      customEventUnsubscribe(SHOW_RESULT_BANNER, showCustomResultBanner);
    };
  }, [isloadingUserData]);

  useEffect(() => {
    if (userData?.userInfo) {
      setIsEmailVerfied(userData.userInfo.accountVerification.isEmailVerfied);
      setIsResidentialStatusProvided(
        userData.userInfo.accountVerification.isResidentialStatusProvided
      );
    }

    const isProcessingRegistration =
      registrationData?.isProcessing ||
      (showLogin && !userData.userInfo?.firstName);
    setIsProcessing(isProcessingRegistration);

    if (isProcessingRegistration) {
      setShowRegistration(false);
    } else if (registrationData.registrationError) {
      handleRegistrationError();
    } else {
      setIsDuplicateAccountError(false);
    }

    handleRegistrationDetailsFormVisibility(
      registrationData?.showRegistrationDetails ?? false
    );
    handleRegistrationResultsBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationData, userData]);

  const handleRegistrationResultsBanner = () => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const isVerificationBanner = searchParams.get("R") === "verification";
    const showResultBanner =
      (registrationData.showRegistrationDetailsResultBanner ||
        registrationData.isReSendVerificationSuccess ||
        isVerificationBanner) ??
      false;

    setResultBannerMessage(
      registrationData.isReSendVerificationSuccess
        ? "Email has been sent"
        : isVerificationBanner
        ? "Your account has been verified"
        : "Success! Your account has been registered!"
    );
    setShowResultBanner(showResultBanner);
  };

  const showCustomResultBanner = (data: any) => {
    setResultBannerMessage(data.detail);
    setShowResultBanner(true);
  };

  const handleRegistrationError = () => {
    setShowRegistration(true);
    switch (registrationData.registrationError) {
      case REGISTRATION_EXISTING_ACCOUNT:
        setIsDuplicateAccountError(true);
        break;
      default:
        break;
    }
  };

  const handleRegistrationDetailsFormVisibility = (
    showRegistrationDetailsForm: boolean
  ) => {
    if (showRegistration) {
      setProcessingMessage("Creating account, please wait");
    } else if (showRegistrationDetailsForm) {
      setProcessingMessage("Updating account, please wait");
    } else if (showLogin) {
      setProcessingMessage("Logging in, please wait");
    }
    setShowRegistrationUserDetails(showRegistrationDetailsForm);
  };

  const createAccount = (idToken?: string) => {
    dispatch(
      createUserAccount({
        firstname: formDataObject.firstname,
        lastname: formDataObject.lastname,
        email: formDataObject.email,
        ssoProvider: formDataObject ? formDataObject.ssoProvider : null,
        idToken,
      }) as any
    );
  };

  if (
    localStorage.getItem("cp-resident-registration-form-data") &&
    window.location.href.indexOf("#id_token=") > -1
  ) {
    const idTokenValue = window.location.hash
      .split("&")[0]
      .replace("#id_token=", "");
    createAccount(idTokenValue);
  }
  if (formDataObject) {
    localStorage.removeItem("cp-resident-registration-form-data");
  }

  const handleRegistrationModalClose = () => {
    setUserDataParameters(null);
    dispatch({
      type: REGISTRATION_CLEAR_ERROR,
      payload: {},
    });
    setShowRegistration(false);
  };

  const handleLogout = () => {
    setIsUserMenuVisible(false);
    dispatch(logout() as any);
  };

  const hideResultBanner = () => {
    window.history.replaceState(null, "", window.location.pathname);
    dispatch({
      type: USER_REGISTRATION_HIDE_BANNER,
    });
    setShowResultBanner(false);
  };

  const showAccountDetails = () => {
    dispatch({
      type: SHOW_REGISTRATION_DETAILS,
      payload: {
        id: userData.userInfo?.id,
        userVerificationCode: userData.userInfo?.userVerificationCode,
      },
    });
  };

  return {
    isMenuVisible,
    currentRoute,
    userInfo: userData.userInfo,
    isUserMenuVisible,
    isNotificationsMenuVisible,
    showRegistration,
    showLogin,
    isProcessing,
    processingMessage,
    showRegistrationUserDetails,
    showResultBanner,
    resultBannerMessage,
    isDuplicateAccountError,
    isEmailVerfied,
    isResidentialStatusProvided,
    showResendEmailModal,
    isloadingUserData,
    userDataParameters,
    loginErrorMessage,
    setIsProcessing,
    setProcessingMessage,
    setShowRegistration,
    setShowLogin,
    setShowRegistrationUserDetails,
    setIsMenuVisible,
    setIsUserMenuVisible,
    setIsNotificationsMenuVisible,
    handleLogout,
    handleRegistrationModalClose,
    hideResultBanner,
    showAccountDetails,
    setShowResendEmailModal,
    notificationsData,
    isLoadingNotifications,
  };
};

export default NavbarLogic;
