import { Link } from "react-router-dom";
import "./Sidebar.scss";

type SidebarItem = {
  text: string;
};

interface SidebarProps {
  items: SidebarItem[];
}

const Sidebar = ({ items }: SidebarProps) => {
  return (
    <div className={"sidebar"}>
      {items.map((item, index) => {
        return (
          <div key={`sidebar_item_${index}`} className={"sidebar-items"}>
            <Link to={"/"} className={"sidebar-item"}>
              {item.text}
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Sidebar;
