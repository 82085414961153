export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTRATION_REQUEST = "USER_REGISTRATION_REQUEST";
export const USER_REGISTRATION_SUCCESS = "USER_REGISTRATION_SUCCESS";
export const USER_REGISTRATION_ERROR = "USER_REGISTRATION_ERROR";
export const USER_REGISTRATION_HIDE_BANNER = "USER_REGISTRATION_HIDE_BANNER";

export const USER_REGISTRATION_DETAILS_REQUEST =
  "USER_REGISTRATION_DETAILS_REQUEST";
export const USER_REGISTRATION_DETAILS_SUCCESS =
  "USER_REGISTRATION_DETAILS_SUCCESS";
export const USER_REGISTRATION_DETAILS_ERROR =
  "USER_REGISTRATION_DETAILS_ERROR";

export const USER_CODE_VERIFICATION_REQUEST = "USER_CODE_VERIFICATION_REQUEST";
export const USER_CODE_VERIFICATION_SUCCESS = "USER_CODE_VERIFICATION_SUCCESS";
export const USER_CODE_VERIFICATION_ERROR = "USER_CODE_VERIFICATION_ERROR";

export const USER_SEND_VERIFICATION_EMAIL_REQUEST =
  "USER_SEND_VERIFICATION_EMAIL_REQUEST";
export const USER_SEND_VERIFICATION_EMAIL_SUCCESS =
  "USER_SEND_VERIFICATION_EMAIL_SUCCESS";
export const USER_SEND_VERIFICATION_EMAIL_ERROR =
  "USER_SEND_VERIFICATION_EMAIL_ERROR";

//TODO: Separate file for common actions?
export const SHOW_REGISTRATION_DETAILS = "SHOW_REGISTRATION_DETAILS";
export const REGISTRATION_CLEAR_ERROR = "REGISTRATION_CLEAR_ERROR";
