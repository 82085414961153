import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import useLogin from "./LoginLogic";

import "./Login.scss";
import { config } from "@App/config/config";
import { Button } from "@Components/index";
import { LoginProps } from "./LoginProps";

Amplify.configure({
  Auth: {
    region: config.region,
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
    mandatorySignIn: true,
    oauth: {
      domain: config.domain,
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: config.redirectSignIn,
      redirectSignOut: config.redirectSignOut,
      responseType: "code",
    },
  },
});

const Login = (props: LoginProps) => {
  useLogin(props);

  return (
    <div className={"login-container"}>
      <div className={"login"}>
        <div className={"login-form"}>
          <header className={"login-form-header"}>
            <img
              src={require("@Assets/images/community_logo_icon.png")}
              alt={""}
            />
            {props.errorMessage && (
              <div className={"login-error-banner"}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={"login-error-banner-icon"}
                >
                  <path
                    d="M16.0002 7.98669L26.0402 25.3334H5.96016L16.0002 7.98669ZM16.0002 2.66669L1.3335 28H30.6668L16.0002 2.66669ZM17.3335 21.3334H14.6668V24H17.3335V21.3334ZM17.3335 13.3334H14.6668V18.6667H17.3335V13.3334Z"
                    fill="#FA3E3E"
                  />
                </svg>
                <div>
                  <div>
                    {props.errorMessage}
                    {` Please Sign in to continue.`}
                  </div>
                  <div>{`You can click 'Forgot your Password?' to reset the password.`}</div>
                </div>
              </div>
            )}
            <label className={"login-title"}>
              {/* TODO: Use community Info data for the name, or config, etc */}
              Welcome Back!
            </label>
          </header>
          <section className={"login-form-fields"}>
            <Authenticator
              // socialProviders={["google", "apple"]}
              hideSignUp={true}
            />
            <div className={"login-form-register"}>
              <button
                className={"login-form-register-button"}
                onClick={() => {
                  props.onClose && props.onClose(true);
                }}
              >
                Create an account
              </button>
            </div>
          </section>
        </div>
        <div className={"login-right-content"}>
          <div
            className={"login-image"}
            style={{
              backgroundImage: `url(${require("@Assets/images/lwr_registration.png")})`,
            }}
          >
            <Button
              type={"close"}
              onClick={() => {
                props.onClose && props.onClose();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
