import axios from "axios";
import { config } from "@App/config/config";
import UserSession from "@App/auth/userSession";
import {
  CompleteUserAccountDetailsRequest,
  RegisterUserRequest,
  SetUserPasswordRequest,
  UserLoginValidationRequest,
} from "./requests/user";

export const registerUser = async (data: RegisterUserRequest) => {
  const response = await axios.post(`${config.backendDomain}/user`, data, {
    headers: {
      "cbp-TenantId": config.cbpTenantId,
    },
  });
  return response;
};

export const completeUserAccountDetails = async (
  data: CompleteUserAccountDetailsRequest
) => {
  const response = await axios.put(
    `${config.backendDomain}/user/${data.id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${UserSession.accessToken}`,
        "cbp-TenantId": config.cbpTenantId,
      },
    }
  );
  return response;
};

export const getUser = async (cognitoUsername: string) => {
  const response = await axios.get(
    `${config.backendDomain}/user/Cognito/${cognitoUsername}`,
    {
      headers: {
        "cbp-TenantId": config.cbpTenantId,
      },
    }
  );
  return response;
};

export const codeVerification = async (code: string) => {
  const response = await axios.post(
    `${config.backendDomain}/user/CodeConfirmationByUser`,
    {
      UserVerificationCode: code,
    },
    {
      headers: {
        Authorization: `Bearer ${UserSession.accessToken}`,
        "cbp-TenantId": config.cbpTenantId,
      },
    }
  );
  return response;
};

export const userLoginValidation = async (data: UserLoginValidationRequest) => {
  const response = await axios.post(
    `${config.backendDomain}/user/Validation/${data.userVerificationCode}`,
    data,
    {
      headers: {
        "cbp-TenantId": config.cbpTenantId,
      },
    }
  );
  return response;
};

export const userPasswordSet = async (data: SetUserPasswordRequest) => {
  const response = await axios.post(
    `${config.backendDomain}/user/Password`,
    data,
    {
      headers: {
        "cbp-TenantId": config.cbpTenantId,
      },
    }
  );
  return response;
};
