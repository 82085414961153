import "./Footer.scss";

const Footer = () => {
  return (
    <div className={"footer"}>
      <div className="trapezoid"></div>
      <div className={"footer-content"}>
        <div className={"footer-content-left"}>
          <div className={"footer-section"}>
            <img
              src={require("@Assets/images/community_logo_icon.png")}
              alt={""}
            />
          </div>
          <div className={"footer-section"}>
            <ul>
              <li>MyLWR Home</li>
              <li>Accessibility</li>
              <li>Copyright</li>
            </ul>
          </div>
          <div className={"footer-section"}>
            <ul>
              <li>Privacy Policy</li>
              <li>Site Map</li>
              <li>info@mylwr.com</li>
            </ul>
          </div>
        </div>
        <div className={"footer-content-right"}>
          <img
            className={"footer-mobile-logo"}
            src={require("@Assets/images/googleplay.png")}
            alt={""}
          />
          <img
            className={"footer-mobile-logo"}
            src={require("@Assets/images/applestore.png")}
            alt={""}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
