import { configureStore, combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import {
  userProfileReducer,
  userRegistrationReducer,
} from "@Store/reducers/userReducer";
import {
  eventCategoriesDataReducer,
  eventsDataReducer,
  upcomingEventsDataReducer,
} from "@Store/reducers/eventReducer";
import {
  generalDataReducer,
  generalMapBoxInfoDataReducer,
} from "@Store/reducers/generalReducer";

const store = configureStore({
  reducer: combineReducers({
    userProfile: userProfileReducer,
    userRegistration: userRegistrationReducer,
    eventsData: eventsDataReducer,
    upcomingEventsData: upcomingEventsDataReducer,
    communityInfo: generalDataReducer,
    mapBoxInfo: generalMapBoxInfoDataReducer,
    eventCategories: eventCategoriesDataReducer,
  }),
  middleware: [thunk],
  devTools: true,
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
