import "./EventItem.scss";
import { Event } from "@App/models/event";
import { Heart } from "@Components/icons";
import { Loader, Tag } from "@Components/index";
import moment from "moment";
import EventItemLogic from "./EventItemLogic";
import { monthName } from "@Utils/utils";
import { Link } from "react-router-dom";
import { handleNotLoggedUserLogin } from "@App/store/actions/eventActions";

interface EventItemProps {
  event: Event;
  isCard?: boolean;
  ignoreAttending?: boolean;
  showLabel: boolean;
  isToday?: boolean;
  notLoggedUser?: boolean;
  onShowAllClick?: () => void;
}

const EventItem = ({
  event,
  isCard,
  ignoreAttending,
  showLabel,
  isToday,
  notLoggedUser,
  onShowAllClick,
}: EventItemProps) => {
  const props = EventItemLogic(event);
  return (
    <div>
      {showLabel && (
        <div className={"event-list-item-header"}>
          <div className={"event-list-item-date-label"}>
            {isToday ? "Today" : `${props.eventStartDate.format("dddd Do")}`}
          </div>
          {onShowAllClick && (
            <button
              className={"event-list-item-show-all-events"}
              onClick={() => {
                onShowAllClick();
              }}
            >
              reset calendar
            </button>
          )}
        </div>
      )}
      <div
        className={`event-list-item ${isCard && "event-list-item-card"} ${
          isToday ? "event-list-item-today" : ""
        }`}
        style={{ flexDirection: isCard ? "column" : "row" }}
      >
        {!props.eventItem.isAdminEvent && (
          <Link
            to={`event-details?id=${props.eventItem.id}`}
            className={"event-list-item-image-wrapper"}
          >
            <Loader
              color={"silver"}
              className={"event-list-item-image-loader"}
            />
            {props.eventItem.images && props.eventItem.images?.length > 0 ? (
              <img
                className={`event-list-item-image ${
                  isCard && "event-list-item-image-card"
                }`}
                src={props.eventItem.images[0].url}
                alt=""
              />
            ) : (
              <img
                className={`event-list-item-image ${
                  isCard && "event-list-item-image-card"
                }`}
                src={require("@Assets/images/event_no_image_placeholder.jpeg")}
                alt=""
              />
            )}
            {!notLoggedUser && isCard && !ignoreAttending && (
              <div className={"event-list-item-image-tag"}>
                <Tag
                  backgroundColor={"#CFF5D5"}
                  text={"Attending"}
                  isStatusTag={true}
                />
              </div>
            )}
          </Link>
        )}

        <div
          className={`event-list-item-info ${
            isCard ? "event-list-item-info-card" : ""
          }`}
        >
          <Link
            type="text"
            to={`event-details?id=${props.eventItem.id}`}
            className={`event-list-item-date-wrapper ${
              isCard ? "event-list-item-date-wrapper-card" : ""
            }`}
          >
            <div className={"event-list-item-date-time-datetime"}>
              {isCard && (
                <div className={"event-list-item-date-time-month"}>
                  {monthName({
                    month: moment(props.eventItem.startDate).get("M"),
                    abbreviation: true,
                  })}
                </div>
              )}
              {!isCard
                ? props.eventItem.startDate === props.eventItem.endDate
                  ? "All Day"
                  : moment(props.eventItem.startDate)
                      .format("h:mm")
                      .replace(":00", "")
                : moment(props.eventItem.startDate).format("D")}
              <div className={"event-list-item-date-time-ext"}>
                {!isCard
                  ? props.eventItem.startDate === props.eventItem.endDate
                    ? "All Day"
                    : moment(props.eventItem.startDate).format("A")
                  : moment(props.eventItem.startDate).format("ddd")}
              </div>
            </div>
          </Link>

          <Link
            to={`event-details?id=${props.eventItem.id}`}
            className={`event-list-item-details-wrapper ${
              isCard ? "event-list-item-details-wrapper-card" : ""
            }`}
          >
            <div
              className={`event-list-item-name ${
                isCard ? "event-list-item-name-card" : ""
              }`}
            >
              {props.eventItem.title}
            </div>
            {isCard && (
              <div className={"event-list-item-detail-text"}>
                {props.eventItem.startDate === props.eventItem.endDate
                  ? "All Day"
                  : `${moment(props.eventItem.startDate).format(
                      "h:mm A"
                    )} - ${moment(props.eventItem.endDate).format("h:mm A")}`}
              </div>
            )}
            <span
              className={"event-list-item-detail-text event-list-item-link"}
            >{`${isCard ? "Location: " : ""}${
              props.eventItem.venue ||
              props.eventItem.address.address1 ||
              props.eventItem.address.address2
            }`}</span>
          </Link>
          <div
            className={`event-list-item-state-wrapper ${
              isCard && "event-list-item-state-wrapper-card"
            } ${
              !isCard &&
              notLoggedUser &&
              "event-list-item-state-wrapper-not-logged"
            }`}
          >
            {!notLoggedUser && props.eventItem.user?.isRSVPd && !isCard && (
              <Tag
                backgroundColor={"#CFF5D5"}
                text={"Attending"}
                isStatusTag={true}
              />
            )}

            {!notLoggedUser &&
              !props.eventItem.isAdminEvent &&
              ((!ignoreAttending && !props.eventItem.user?.isRSVPd) ||
                ignoreAttending) && (
                <button
                  className={"event-list-item-fav"}
                  style={{ marginTop: props.isFavPreferenceProcessing ? 5 : 0 }}
                  disabled={props.isFavPreferenceProcessing}
                  onClick={() => {
                    props.updateEventPreference(
                      "isFavorite",
                      !props.eventItem.user?.isFavorite
                    );
                  }}
                >
                  {props.isFavPreferenceProcessing ? (
                    <Loader color={"silver"} width="20" height="10" />
                  ) : (
                    <Heart isActive={props.eventItem.user?.isFavorite} />
                  )}
                </button>
              )}
            {!isCard &&
              !props.eventItem.isAdminEvent &&
              props.eventItem.isRsvpEnabled &&
              !props.eventItem.user?.isRSVPd && (
                <button
                  className={
                    "event-list-item-state-button event-list-item-link"
                  }
                  style={{
                    marginBottom: props.isRsvpPreferenceProcessing ? 10 : 0,
                    marginRight: props.isRsvpPreferenceProcessing ? 20 : 0,
                  }}
                  disabled={props.isRsvpPreferenceProcessing}
                  onClick={() => {
                    if (notLoggedUser) {
                      handleNotLoggedUserLogin(props.eventItem);
                    } else {
                      props.updateEventPreference(
                        "isRSVPd",
                        !props.eventItem.user?.isRSVPd
                      );
                    }
                  }}
                >
                  {props.isRsvpPreferenceProcessing ? (
                    <Loader color={"silver"} width="20" height="10" />
                  ) : (
                    `${notLoggedUser ? "Login to Confirm" : "Confirm"}`
                  )}
                </button>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventItem;
