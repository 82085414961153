type SvgsProps = {
  color?: string;
};

const Bell = ({ color = "#858383" }: SvgsProps) => {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.27273 18C8.27273 18 9.09091 17.1818 9.09091 16.1818H5.45455C5.45455 17.1818 6.27273 18 7.27273 18ZM12.7273 12.5454V7.99998C12.7273 5.20907 11.2455 2.8727 8.63636 2.25452V1.63634C8.63636 0.881796 8.02727 0.272705 7.27273 0.272705C6.51818 0.272705 5.90909 0.881796 5.90909 1.63634V2.25452C3.30909 2.8727 1.81818 5.19998 1.81818 7.99998V12.5454L0 14.3636V15.2727H14.5455V14.3636L12.7273 12.5454ZM10.9091 13.4545H3.63636V7.99998C3.63636 5.74543 5.00909 3.90907 7.27273 3.90907C9.53636 3.90907 10.9091 5.74543 10.9091 7.99998V13.4545Z"
        fill={color}
      />
    </svg>
  );
};

export default Bell;
