import React from "react";
import { Link } from "react-router-dom";
import { navbarLinks } from "../../utils/dummy";
import { Sidebar } from "@Layouts/index";
import "./Navbar.scss";
import NavbarLogic from "./NavbarLogic";
import Footer from "@Layouts/footer/Footer";
import {
  ActionBanner,
  ContextMenu,
  Loader,
  Modal,
  RegistrationDetails,
  ResendEmail,
  ResultBanner,
  Menu,
  NotificationItem,
} from "@Components/index";
import { Login, Registration } from "@Pages/index";
import { Calendar, Bell } from "@Components/icons";
import { RSVP_LOGIN_FLOW } from "@App/constants/appConstants";

interface NavbarLinkProps {
  text: string;
  route: string;
  isActive: boolean;
  onClick: () => void;
}

const NavbarLink = ({ text, route, isActive, onClick }: NavbarLinkProps) => {
  return (
    <Link
      className={`${isActive ? "active" : ""}`}
      to={route}
      onClick={onClick}
    >
      {text}
    </Link>
  );
};

const NavbarLinks = ({
  isMenuVisible,
  setIsMenuVisible,
  currentRoute,
}: {
  isMenuVisible: boolean;
  setIsMenuVisible: any;
  currentRoute: string;
}): JSX.Element => {
  return (
    <nav className={`nav-menu ${isMenuVisible ? "nav-menu-visible" : ""}`}>
      <ul className={`nav-links ${isMenuVisible ? "nav-links-visible" : ""}`}>
        {navbarLinks.map((navbarLink, index) => {
          return (
            <li
              key={`navbar_link_${index}`}
              className={`nav-links ${
                isMenuVisible ? "nav-links-visible" : ""
              }`}
            >
              <NavbarLink
                text={navbarLink.text}
                route={navbarLink.route}
                isActive={currentRoute === navbarLink.route}
                onClick={() => setIsMenuVisible(false)}
              />
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

const Navbar = ({
  children,
  sidebarItems,
}: {
  children: any;
  sidebarItems?: any;
}) => {
  const {
    isMenuVisible,
    userInfo,
    isUserMenuVisible,
    isNotificationsMenuVisible,
    showRegistration,
    showLogin,
    isProcessing,
    processingMessage,
    showRegistrationUserDetails,
    showResultBanner,
    resultBannerMessage,
    isDuplicateAccountError,
    isEmailVerfied,
    isResidentialStatusProvided,
    showResendEmailModal,
    isloadingUserData,
    userDataParameters,
    currentRoute,
    loginErrorMessage,
    setIsProcessing,
    setShowRegistration,
    setShowLogin,
    setShowRegistrationUserDetails,
    setIsMenuVisible,
    setIsUserMenuVisible,
    setIsNotificationsMenuVisible,
    handleLogout,
    handleRegistrationModalClose,
    hideResultBanner,
    showAccountDetails,
    setShowResendEmailModal,
    notificationsData,
    isLoadingNotifications,
  } = NavbarLogic();
  const communityLogo = require("@Assets/images/community_logo_icon.png");
  const isLogged = userInfo?.firstName;

  return (
    <React.Fragment>
      {!isEmailVerfied && (
        <ActionBanner
          text={"Please check your email to verify your account."}
          noUnderline
          actionLinkText={"Click here to resend"}
          actionClick={() => {
            setShowResendEmailModal(true);
          }}
        />
      )}
      {isEmailVerfied && !isResidentialStatusProvided && (
        <ActionBanner
          text={
            "Account Validation Required: Please click here to ensure your account setup is complete!"
          }
          onClick={() => {
            window.history.replaceState(null, "", window.location.pathname);
            showAccountDetails();
          }}
        />
      )}
      <div className={"nav-container"}>
        <div
          className={"nav-wrapper"}
          onClick={() => {
            // setIsUserMenuVisible(false);
          }}
        >
          <div className="nav">
            <Link
              to={"/"}
              className={"nav-logo-container"}
              onClick={() => {
                setIsUserMenuVisible(false);
              }}
            >
              <img className={"nav-logo"} src={communityLogo} alt={"logo"} />
            </Link>
            <NavbarLinks
              isMenuVisible={isMenuVisible}
              setIsMenuVisible={setIsMenuVisible}
              currentRoute={currentRoute}
            />
            <div
              className={"nav-right-content"}
              onMouseLeave={() =>
                setTimeout(() => setIsNotificationsMenuVisible(false), 400)
              }
            >
              <Link
                className={"nav-button-icon"}
                to={"/calendar"}
                onClick={() => setIsNotificationsMenuVisible(false)}
              >
                <Calendar
                  color={currentRoute === "/calendar" ? "#000" : "#858383"}
                />
              </Link>
              {isLogged && (
                <div
                  className={"nav-button-icon nav-button-icon__tall"}
                  onMouseOver={() => setIsNotificationsMenuVisible(true)}
                >
                  <Link to={"/notifications"} style={{ marginTop: "3px" }}>
                    <Bell
                      color={
                        currentRoute === "/notifications" ? "#000" : "#858383"
                      }
                    />
                  </Link>
                  {isNotificationsMenuVisible && (
                    <Menu backgroundColor="#FFFFFF">
                      <Link
                        to={"/notifications"}
                        style={{ textDecoration: "none" }}
                      >
                        {isLoadingNotifications ? (
                          <Loader color={"#000"} />
                        ) : (
                          notificationsData?.pages[0].items
                            .slice(0, 5)
                            .map(
                              ({
                                id,
                                title,
                                message,
                                isRead,
                                category,
                                createDate,
                              }) => {
                                return (
                                  <NotificationItem
                                    key={id}
                                    title={title}
                                    bodyText={message}
                                    isRead={isRead}
                                    category={category}
                                    dateISO={createDate}
                                    maxWidth="375px"
                                    isMenuItem
                                  />
                                );
                              }
                            )
                        )}
                      </Link>
                    </Menu>
                  )}
                </div>
              )}
              <button
                className={"nav-user"}
                onClick={() => {
                  if (isLogged) {
                    setIsNotificationsMenuVisible(false);
                    setIsUserMenuVisible(!isUserMenuVisible);
                  } else {
                    localStorage.removeItem(RSVP_LOGIN_FLOW);
                    setShowLogin(true);
                  }
                }}
              >
                <div className={"nav-user-text"}>{isLogged ?? `Log in`}</div>
              </button>
              <button
                className={"nav-user"}
                onClick={() => {
                  setShowRegistration(true);
                }}
              >
                {!userInfo && (
                  <div className={"nav-user-text"}>{`sign up`}</div>
                )}
              </button>
              <Modal
                isVisible={showLogin}
                onClose={() => {
                  setShowLogin(false);
                }}
                children={
                  <Login
                    errorMessage={loginErrorMessage}
                    onClose={(showRegistration) => {
                      setShowLogin(false);
                      if (showRegistration) {
                        setShowRegistration(true);
                      }
                    }}
                  />
                }
              />
              <Modal
                isVisible={showRegistration}
                onClose={() => {
                  handleRegistrationModalClose();
                }}
                children={
                  <Registration
                    onClose={() => {
                      handleRegistrationModalClose();
                    }}
                    duplicateAccountError={isDuplicateAccountError}
                    userData={userDataParameters}
                  />
                }
              />
              <Modal
                isVisible={showRegistrationUserDetails}
                onClose={() => {}}
                children={
                  <RegistrationDetails
                    onSave={() => {
                      setShowRegistrationUserDetails(false);
                      setIsProcessing(true);
                      setTimeout(() => {
                        setIsProcessing(false);
                      }, 2000);
                    }}
                    onClose={() => {
                      setShowRegistrationUserDetails(false);
                    }}
                  />
                }
              />
              {isUserMenuVisible && (
                <ContextMenu
                  items={
                    isLogged
                      ? [
                          {
                            text: "Logout",
                            type: "button",
                            action: handleLogout,
                          },
                        ]
                      : [
                          {
                            text: "Login",
                            type: "button",
                            action: () => {
                              const loc =
                                "http://" + window.location.host + "/login";
                              window.location.href = loc;
                            },
                          },
                          {
                            text: "Register",
                            type: "button",
                            action: () => {
                              const loc =
                                "http://" + window.location.host + "/login";
                              window.location.href = loc;
                            },
                          },
                        ]
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div
          className={"nav-content-contanier"}
          onClick={() => {
            setIsUserMenuVisible(false);
          }}
        >
          {sidebarItems && (
            <div className={"nav-sidebar"}>
              <Sidebar
                items={[
                  { text: "Item 1" },
                  { text: "Item 2" },
                  { text: "Item 3" },
                  { text: "Item 4" },
                  { text: "Item 5" },
                ]}
              />
            </div>
          )}
          {/* Content */}
          <div
            className={`nav-content ${sidebarItems && "nav-content-sidebar"}`}
          >
            {showResultBanner && isLogged && (
              <ResultBanner
                text={resultBannerMessage}
                onClose={() => {
                  hideResultBanner();
                }}
              />
            )}
            {children}
          </div>
        </div>
      </div>
      <footer>
        <Footer />
      </footer>
      {(isProcessing || isloadingUserData) && (
        <Loader text={processingMessage} modal />
      )}
      <ResendEmail
        isVisible={showResendEmailModal}
        onClose={() => {
          setShowResendEmailModal(false);
        }}
      />
    </React.Fragment>
  );
};

export default Navbar;
