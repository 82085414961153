import "./Notifications.scss";
import { Navbar } from "@Layouts/index";
import NotificationsLogic from "./NotificationsLogic";
import { NotificationItem, Tag, Loader } from "@Components/index";

/* TODO: Switch notification index for id when backend is connected */
const Notifications = () => {
  const {
    data,
    isLoading,
    ref,
    hasNextPage,
    showUnreadNotifications,
    setShowUnreadNotifications,
  } = NotificationsLogic();

  if (isLoading && !data) return <Loader color={"#000"} />;

  return (
    <div className={"notifications-container"}>
      <Navbar>
        <section className={"notifications-section-wrapper"}>
          <div className={"notifications-section"}>
            <h1 className={"notifications-section__title"}>NOTIFICATIONS</h1>
            <div className={"notifications-section__header"}>
              <Tag
                text="All"
                onClick={() => setShowUnreadNotifications(false)}
                isSelected={!showUnreadNotifications}
              />
              <Tag
                text="Unread"
                onClick={() => setShowUnreadNotifications(true)}
                isSelected={showUnreadNotifications}
              />
            </div>
            <div className={"notifications-section__list"}>
              {data?.pages
                .flatMap((page) => page.items)
                .filter((notification) =>
                  showUnreadNotifications ? !notification.isRead : true
                )
                .map(
                  (
                    { id, title, message, isRead, category, createDate },
                    index
                  ) => {
                    return (
                      <NotificationItem
                        key={index}
                        title={title}
                        bodyText={message}
                        isRead={isRead}
                        category={category}
                        dateISO={createDate}
                      />
                    );
                  }
                )}
              {hasNextPage && (
                <div ref={ref}>
                  <Loader color={"#000"} />
                </div>
              )}
            </div>
          </div>
        </section>
      </Navbar>
    </div>
  );
};

export default Notifications;
