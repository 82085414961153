import { useEffect, useState, useCallback } from "react";
import { getNotifications } from "@App/api/notifications";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { QUERY_KEY_NOTIFICATIONS } from "@App/constants/queryKeyConstants";

const NotificationsLogic = () => {
  const [showUnreadNotifications, setShowUnreadNotifications] = useState(false);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: [QUERY_KEY_NOTIFICATIONS],
      queryFn: ({ pageParam }) =>
        getNotifications({ page: pageParam }).then((res) => res.data),
      getNextPageParam: (lastPage) => {
        if (lastPage.isLastPage) {
          return;
        } else {
          return lastPage.page + 1;
        }
      },
    });

  const { ref, inView } = useInView({
    threshold: 1,
    rootMargin: "100px",
  });

  const onIntersect = useCallback(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    onIntersect();
  }, [onIntersect]);

  return {
    data,
    isLoading,
    ref,
    showUnreadNotifications,
    setShowUnreadNotifications,
    hasNextPage,
  };
};

export default NotificationsLogic;
