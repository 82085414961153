import { useEffect, useRef, useState } from "react";
import { Event } from "@App/models/event";
import moment from "moment";
import {
  getEventsList,
  updateEventUserPreferences,
} from "@App/store/actions/eventActions";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { SHOW_RESULT_BANNER } from "@App/constants/appConstants";
import { customEventPublish } from "@Utils/utils";

const EventItemLogic = (event: Event) => {
  const [eventItem, setEventItem] = useState(event);
  const eventStartDate = moment(event.startDate);
  const [isFavPreferenceProcessing, setIsFavPreferenceProcessing] =
    useState(false);
  const [isRsvpPreferenceProcessing, setIsRsvpPreferenceProcessing] =
    useState(false);

  const prevEvent = useRef<Event>();

  const dispatch = useDispatch();

  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile
  );

  const { userInfo } = userData;

  const refreshEvents = () => {
    const date = new Date(event.startDate);

    //Get all events for the selected month
    dispatch(
      getEventsList({
        startDate: date,
        endDate: date,
        isRefresh: true,
      }) as any
    );

    //Get all the updated events (Upcoming) to get the updated Favs and RSVPs
    dispatch(
      getEventsList({
        startDate: date,
        endDate: date,
        isRefresh: true,
        isUpcoming: true,
      }) as any
    );
  };

  useEffect(() => {
    setEventItem(event);

    if (
      !prevEvent.current ||
      (prevEvent.current && isFavPreferenceProcessing)
    ) {
      setIsFavPreferenceProcessing(false);
      setIsRsvpPreferenceProcessing(false);
    }
    prevEvent.current = event;
  }, [userInfo, event, isFavPreferenceProcessing]);

  const updateEventPreference = (
    preferenceName: string,
    preferenceValue: boolean
  ) => {
    if (userInfo) {
      if (preferenceName === "isRSVPd") {
        setIsRsvpPreferenceProcessing(true);
      } else {
        setIsFavPreferenceProcessing(true);
      }
      updateEventUserPreferences({
        eventId: event.id,
        userId: userInfo.id,
        preference: { [preferenceName]: preferenceValue },
      }).then((response) => {
        if (response.isSuccess) {
          if (preferenceValue) {
            customEventPublish(
              SHOW_RESULT_BANNER,
              `You've ${
                preferenceName === "isRSVPd" ? "RSVP" : "Favorited"
              } for this event!`
            );
          }

          refreshEvents();
        }
      });
    }
  };

  return {
    eventItem,
    eventStartDate,
    isFavPreferenceProcessing,
    isRsvpPreferenceProcessing,
    updateEventPreference,
  };
};

export default EventItemLogic;
