import {
  createUserAccount,
  setUserPassword,
} from "@App/store/actions/userActions";
import { EmailRegx, PasswordRegx, customEventPublish } from "@Utils/utils";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { config } from "@App/config/config";
import { RegistrationProps } from "./RegistrationProps";
import { SHOW_LOGIN_MODAL } from "@App/constants/appConstants";

const useRegistrationLogic = (props: RegistrationProps) => {
  //TODO: Remove this when we add SSO (and we don't need to persist navigation)
  const formData = localStorage.getItem("cp-resident-registration-form-data");
  //TODO: (isPreSignupFlow) BE needed to connect the user login (with username and pass or SSO)
  // const isPreSignupFlow = !!userData;
  let formDataObject = formData
    ? JSON.parse(formData)
    : props.userData
    ? props.userData
    : null;
  const [firstname, setFirstname] = useState(
    formDataObject ? formDataObject.firstname : String
  );
  const [lastname, setlastname] = useState(
    formDataObject ? formDataObject.lastname : String
  );
  const [email, setEmail] = useState(
    formDataObject ? formDataObject.email : String
  );
  const [password, setPassword] = useState(String);
  const [passwordConfirmation, setPasswordConfirmation] = useState(String);
  const [validFirstname, setValidFirstname] = useState(true);
  const [validLastname, setValidLastname] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [validPasswordConfirmation, setValidPasswordConfirmation] =
    useState(true);
  const [validForm, setValidForm] = useState(false);
  const [validFormSso, setValidFormSso] = useState(false);
  const [isPasswordResetProcessing, setIsPasswordResetProcessing] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    const validateFields = () => {
      if (firstname?.length > 0) {
        setValidFirstname(firstname?.length > 1);
      }
      if (lastname?.length > 0) {
        setValidLastname(lastname?.length > 1);
      }
      if (email?.length > 0) {
        setValidEmail(EmailRegx.test(email));
      }
      if (password?.length > 0) {
        setValidPassword(PasswordRegx.test(password));
      }
      if (password?.length > 0 && passwordConfirmation?.length > 0) {
        setValidPasswordConfirmation(password === passwordConfirmation);
      }
      setValidForm(
        firstname?.length > 1 &&
          lastname?.length > 1 &&
          EmailRegx.test(email) &&
          PasswordRegx.test(password) &&
          password === passwordConfirmation
      );

      setValidFormSso(
        firstname?.length > 1 && lastname?.length > 1 && EmailRegx.test(email)
      );
    };
    validateFields();
  }, [firstname, lastname, email, password, passwordConfirmation]);

  const handleSocialButtonClick = ({ isGoogle }: { isGoogle: boolean }) => {
    localStorage.setItem(
      "cp-resident-registration-form-data",
      JSON.stringify({
        firstname,
        lastname,
        email,
        ssoProvider: isGoogle ? "Google" : "SignInWithApple",
      })
    );
    window.location.href = isGoogle
      ? `https://accounts.google.com/o/oauth2/auth?client_id=${config.googleClientId}&redirect_uri=${config.registrationRedirectUri}&response_type=id_token&approval_prompt=force&scope=openid email`
      : `https://appleid.apple.com/auth/authorize?client_id=${config.appleClientId}&redirect_uri=${config.registrationRedirectUri}&response_type=code id_token&scope=openid&response_mode=`;
  };

  const onRegisterUser = (userData?: any) => {
    setErrorMessage("");
    if (userData) {
      setIsPasswordResetProcessing(true);
      setUserPassword({
        email,
        userVerificationCode: userData.code,
        password,
      }).then((response) => {
        if (response.success) {
          setIsPasswordResetProcessing(false);
          props.onClose && props.onClose();
          customEventPublish(SHOW_LOGIN_MODAL, {});
        } else {
          setErrorMessage(
            "Error while trying to reset the password. Please try again, or contact support"
          );
        }
      });
    } else {
      dispatch(
        createUserAccount({
          firstname,
          lastname,
          email,
          password,
        }) as any
      );
    }
  };

  return {
    firstname,
    lastname,
    email,
    password,
    passwordConfirmation,
    validFirstname,
    validLastname,
    validEmail,
    validPassword,
    validPasswordConfirmation,
    validForm,
    validFormSso,
    isPasswordResetProcessing,
    errorMessage,
    setFirstname,
    setlastname,
    setEmail,
    setPassword,
    setPasswordConfirmation,
    onRegisterUser,
    handleSocialButtonClick,
  };
};

export default useRegistrationLogic;
