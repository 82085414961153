import React from "react";
import { useState } from "react";
import "./Dropdown.scss";

interface DropdownProps {
  label?: string;
  items: DropdownItem[];
  tooltip?: string;
  tooltipPosition?: "left" | "top" | "right" | "bottom";
  disabled?: boolean;
  required?: boolean;
  onChange?: (item: any) => void;
}

type DropdownItem = {
  label?: string;
  value: string;
};

const DropdownItems = ({
  items,
  setSelected,
  setIsExpanded,
  onChange,
}: any) => {
  const handleSelected = (selectedItem: DropdownItem) => {
    setSelected(selectedItem.label);
    setIsExpanded(false);
    onChange && onChange(selectedItem);
  };

  return (
    <ul>
      {items?.map((item: DropdownItem, index: number) => (
        <li key={`dropdown_item${index}`}>
          <button
            onClick={() => {
              handleSelected(item);
            }}
          >
            {item.label}
          </button>
        </li>
      ))}
    </ul>
  );
};

const Dropdown = ({
  label,
  items,
  tooltip,
  tooltipPosition = "left",
  disabled,
  required,
  onChange,
}: DropdownProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selected, setSelected] = useState("");

  return (
    <React.Fragment>
      <div className={"dropdown"} aria-haspopup="true">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {!!label && (
            <label className={"dropdown-label"}>{`${label}${
              required ? "*" : ""
            }`}</label>
          )}
          {!!tooltip && (
            <div className={"tooltip"}>
              <span className={`tooltiptext tooltip-${tooltipPosition}`}>
                {tooltip}
              </span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                  fill="#334150"
                />
              </svg>
            </div>
          )}
        </div>

        <button
          className={`btn-dropdown ${isExpanded && "is-open"}`}
          disabled={disabled}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          <span style={{ marginTop: 3 }}>
            {selected?.length ? selected : "Select"}
          </span>
        </button>
        <nav className={`nav-dropdown ${isExpanded && "is-open"}`}>
          <DropdownItems
            items={items}
            setSelected={setSelected}
            setIsExpanded={setIsExpanded}
            onChange={onChange}
          />
        </nav>
      </div>
    </React.Fragment>
  );
};

export default Dropdown;
