import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "@Store/reducers/userReducer";
import { RootState } from "@Store/store";
import { completeAccountDetails } from "@Store/actions/userActions";
import { EmailRegx, ZipRegx } from "@Utils/utils";

const RegistrationDetailsLogic = () => {
  const dispatch = useDispatch();
  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile
  );
  const { userInfo } = userData;
  const [showRegistrationDetails, setShowRegistrationDetails] = useState(false);
  const [memberType, setMemberType] = useState("1");
  const [address1, setAddress1] = useState(String);
  const [address2, setAddress2] = useState(String);
  const [state, setState] = useState(String);
  const [city, setCity] = useState(String);
  const [zip, setZip] = useState(String);
  const [validAddress1, setValidAddress1] = useState(true);
  const [validState, setValidState] = useState(true);
  const [validCity, setValidCity] = useState(true);
  const [validZip, setValidZip] = useState(true);
  const [validForm, setValidForm] = useState(false);
  const [marketingCommunicationsEnabled, setMarketingCommunicationsEnabled] =
    useState(false);

  const [email, setEmail] = useState(String || null);
  const [firstname, setFirstname] = useState(String || null);
  const [lastname, setLastname] = useState(String || null);
  const [validFirstname, setValidFirstname] = useState(true);
  const [validLastname, setValidLastname] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [userDataLoaded, setUserDataLoaded] = useState(false);

  useEffect(() => {
    if (
      userInfo?.email &&
      userInfo?.firstName &&
      userInfo?.lastName &&
      !email
    ) {
      setEmail(userInfo?.email);
      setFirstname(userInfo?.firstName);
      setLastname(userInfo?.lastName);
      setMarketingCommunicationsEnabled(userInfo?.isMarketingEnabled ?? false);
      setUserDataLoaded(true);
    }
    const validateFields = () => {
      if (firstname && firstname.length > 0) {
        setValidFirstname(firstname?.length > 1);
      }
      if (lastname && lastname.length > 0) {
        setValidLastname(lastname?.length > 1);
      }
      if (email && email.length > 0) {
        setValidEmail(EmailRegx.test(email));
      }

      if (memberType === "1") {
        if (address1?.length > 0) {
          setValidAddress1(address1?.length > 1);
        }
        if (state?.length > 0) {
          setValidState(state?.length > 1);
        }
        if (city?.length > 0) {
          setValidCity(city?.length > 1);
        }
        if (zip?.length > 0) {
          setValidZip(ZipRegx.test(zip));
        }
        setValidForm(
          firstname?.length > 1 &&
            lastname?.length > 1 &&
            EmailRegx.test(email) &&
            address1?.length > 1 &&
            state?.length > 1 &&
            city?.length > 1 &&
            ZipRegx.test(zip)
        );
      } else if (memberType === "2") {
        setValidForm(
          firstname?.length > 1 && lastname?.length > 1 && EmailRegx.test(email)
        );
      }
    };
    validateFields();
  }, [
    address1,
    address2,
    state,
    city,
    zip,
    memberType,
    firstname,
    lastname,
    email,
    userInfo,
  ]);

  const saveRegistrationDetails = () => {
    if (userInfo?.id) {
      dispatch(
        completeAccountDetails({
          id: userInfo.id,
          firstname,
          lastname,
          userType: memberType,
          address: {
            address1,
            address2,
            city,
            state,
            zip,
          },
          isMarketingEnabled: marketingCommunicationsEnabled,
        }) as any
      );
    }
  };

  return {
    showRegistrationDetails,
    setShowRegistrationDetails,
    memberType,
    setMemberType,
    address1,
    setAddress1,
    address2,
    setAddress2,
    state,
    setState,
    city,
    setCity,
    zip,
    setZip,
    validAddress1,
    setValidAddress1,
    validState,
    setValidState,
    validCity,
    setValidCity,
    validZip,
    setValidZip,
    validForm,
    setValidForm,
    marketingCommunicationsEnabled,
    setMarketingCommunicationsEnabled,
    saveRegistrationDetails,
    userDataLoaded,
    //TODO: These could be temp until we add SSO
    email,
    setEmail,
    validEmail,
    firstname,
    setFirstname,
    validFirstname,
    lastname,
    setLastname,
    validLastname,
  };
};

export default RegistrationDetailsLogic;
