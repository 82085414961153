import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Hub } from "aws-amplify";
import UserSession from "@App/auth/userSession";
import { useDispatch } from "react-redux";
import { getUserDetails } from "@App/store/actions/userActions";
import { getUpcomingEvents } from "@App/store/actions/eventActions";
import { LoginProps } from "./LoginProps";

const useLogin = (props: LoginProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (UserSession.accessToken) {
      handleLoggedUser();
    }
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          UserSession.accessToken = data.signInUserSession.accessToken.jwtToken;
          UserSession.cognitoUsername = data.username;
          handleLoggedUser();
          dispatch(getUpcomingEvents() as any);
          break;
        case "signOut":
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Error", data);
          break;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, dispatch]);

  const handleLoggedUser = () => {
    dispatch(getUserDetails() as any);
    props.onClose && props.onClose();
  };
};

export default useLogin;
